import React from 'react';

import Banner from '@common/banner';
import HowAdGroupsWork from '@common/sideInfoSheets/how-ad-groups-work';
import HowBudgetingWorks from '@common/sideInfoSheets/how-budgeting-works';
import HowCampaignGoalsWork from '@common/sideInfoSheets/how-campaign-goals-work';
import HowCampaignsWork from '@common/sideInfoSheets/how-campaigns-work';
import LetUsChooseYourImage from '@common/sideInfoSheets/let-us-choose-your-image';
import PartnerAccountInfo from '@common/sideInfoSheets/partner-account-info';
import PaymentProfileInfo from '@common/sideInfoSheets/payment-profile-info';
import { SidePanel } from '@components/campaignManagement/campaignWizard/side-panel';
import { EGDSPrimaryButton } from '@egds/react-core';
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from '@egds/react-core';
import { EGDSLayoutGrid, EGDSLayoutGridItem } from '@egds/react-core';
import { EGDSScrim, EGDSScrimType } from '@egds/react-core';
import { EGDSSheet, EGDSSheetContent, EGDSSheetFooter, EGDSSheetTransition } from '@egds/react-core';
import { EGDSSpacing } from '@egds/react-core';
import { EGDSToolbar } from '@egds/react-core';
import useClickOutside from '@hooks/use-click-outside';
import { useLocalization } from '@shared-ui/localization-context';
import useMeasure from 'react-use-measure';
import { LayoutContextProvider } from 'src/context/layout-context';
import { useSideInfoSheetContext } from 'src/context/side-info-sheet-context';

import TopNavBar from './layout-content/top-nav-bar';

interface IWizardLayoutProps {
  sideNav: React.ReactNode;
  main: React.ReactNode;
  aside?: React.ReactNode;
  footer?: React.ReactNode;
  hasPageTitle: boolean;
}

const WizardLayout: React.FC<IWizardLayoutProps> = ({ sideNav, main, aside, footer, hasPageTitle }) => {
  const [mainContainerRef, bounds] = useMeasure();
  const { showSideSheet } = useSideInfoSheetContext();

  let mainClass = 'wizard-main';
  if (!footer) {
    mainClass += ' full-height';
  }

  const collapse = bounds.width < 991;
  return (
    <LayoutContextProvider bounds={bounds}>
      {showSideSheet && (
        <EGDSScrim style={{ cursor: 'none' }} className="side-info-sheet-scrim" type={EGDSScrimType.OVERLAY} />
      )}
      <SideInfoSheet />
      <TopNavBar navOpen setNavOpen={() => {}} wizardMode />
      <div className="nav-container nav-open">
        {sideNav}
        <main className={mainClass} ref={mainContainerRef}>
          <Banner />
          <EGDSLayoutGrid columns={collapse ? ['3fr'] : ['2fr', '1fr']} alignContent="start" style={{ maxWidth: 1200 }}>
            <EGDSLayoutGridItem>
              <div style={{ padding: '1.5rem', paddingRight: collapse ? '1.5rem' : '0.75rem' }}>{main}</div>
            </EGDSLayoutGridItem>
            {aside && <SidePanel collapse={collapse} content={aside} marginTop={hasPageTitle ? '3rem' : ''} />}
          </EGDSLayoutGrid>
          {footer && footer}
        </main>
      </div>
    </LayoutContextProvider>
  );
};

export default WizardLayout;

// Eventually the content of each sheet type should come from singular graphql query
// And each type will be a fragment
const SideInfoSheet = () => {
  const { formatText } = useLocalization();
  const ref = React.useRef();
  const { showSideSheet, setShowSideSheet, sideSheetTriggerRef, sheetName, setSheetName } = useSideInfoSheetContext();
  useClickOutside(ref, () => {
    if (showSideSheet) {
      setShowSideSheet(false);
      setSheetName(null);
    }
  });

  let sheetContent = null;
  let sheetDisplay = null;
  switch (sheetName) {
    case 'HOW_CAMPAIGNS_WORK':
      sheetContent = <HowCampaignsWork />;
      sheetDisplay = formatText('common.sideInfoSheets.howCampaignsWorkHeading');
      break;
    case 'HOW_AD_GROUPS_WORK':
      sheetContent = <HowAdGroupsWork />;
      break;
    case 'HOW_BUDGETING_WORKS':
      sheetContent = <HowBudgetingWorks />;
      sheetDisplay = formatText('common.sideInfoSheets.howBudgetsWorkHeading');
      break;
    case 'HOW_CAMPAIGN_GOALS_WORK':
      sheetContent = <HowCampaignGoalsWork />;
      sheetDisplay = formatText('common.sideInfoSheets.campaignGoalHeading');
      break;
    case 'LET_US_CHOOSE_YOUR_IMAGE':
      sheetContent = <LetUsChooseYourImage />;
      sheetDisplay = formatText('common.sideInfoSheets.letUsChooseYourImageHeading');
      break;
    case 'PARTNER_ACCOUNT_INFO':
      sheetContent = <PartnerAccountInfo />;
      sheetDisplay = formatText('common.sideInfoSheets.partnerAccountHeading');
      break;
    case 'PAYMENT_PROFILE_INFO':
      sheetContent = <PaymentProfileInfo />;
      sheetDisplay = formatText('common.sideInfoSheets.paymentProfileHeading');
      break;
    default:
      sheetContent = null;
  }

  if (!showSideSheet || !sheetName || !sheetContent) {
    return null;
  }

  return (
    <>
      <div ref={ref}>
        <EGDSSheetTransition isVisible={showSideSheet}>
          <EGDSSheet isVisible={showSideSheet} type="side" triggerRef={sideSheetTriggerRef}>
            <EGDSToolbar
              navigationContent={{
                onClick: () => setShowSideSheet(false),
                navIconLabel: 'Close',
              }}
              toolbarTitle={sheetDisplay}
            />
            <EGDSSheetContent>{sheetContent}</EGDSSheetContent>
            <EGDSSheetFooter>
              <EGDSSpacing margin={{ block: 'eight' }} padding={{ inline: 'eight' }}>
                <EGDSLayoutFlex space="twelve" justifyContent="center">
                  <EGDSLayoutFlexItem grow={1}>
                    <EGDSPrimaryButton onClick={() => setShowSideSheet(false)}>
                      {formatText('wizardLayout.sideInfoSheetCloseButtonText')}
                    </EGDSPrimaryButton>
                  </EGDSLayoutFlexItem>
                </EGDSLayoutFlex>
              </EGDSSpacing>
            </EGDSSheetFooter>
          </EGDSSheet>
        </EGDSSheetTransition>
      </div>
    </>
  );
};
