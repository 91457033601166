import React from 'react';

import { EGDSCard, EGDSCardContentSection } from '@egds/react-core';
import { EGDSLineSkeleton } from '@egds/react-core';

const LoadingSkeletonCard: React.FC = () => {
  return (
    <EGDSCard padded={false}>
      <EGDSCardContentSection>
        <EGDSLineSkeleton />
      </EGDSCardContentSection>
      <EGDSCardContentSection>
        <EGDSLineSkeleton animation={true} type="secondary" />
      </EGDSCardContentSection>
    </EGDSCard>
  );
};

export default LoadingSkeletonCard;
