//@typescript-eslint/no-explicit-any
import React from 'react';

import { EGDSButtonSize, EGDSSecondaryButton, EGDSTertiaryButton } from '@egds/react-core';
import { EGDSIcon } from '@egds/react-core';
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from '@egds/react-core';
import { EGDSLayoutPosition } from '@egds/react-core';
import { EGDSLink } from '@egds/react-core';
import { EGDSSheet, EGDSSheetContent, EGDSSheetTransition } from '@egds/react-core';
import { EGDSSpacingHr } from '@egds/react-core';
import { EGDSHeading, EGDSSubheading, EGDSText } from '@egds/react-core';
import { useStores } from '@hooks/use-stores';
import { useLocalization } from '@shared-ui/localization-context';
import { useDyffContext } from 'dyff-plugin/dist/context';

interface UserPopoverProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: any;
  visible: boolean;
  toggleVisibility: () => void;
  openDialog: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  triggerRef: React.RefObject<any>;
}

export const UserPopoverSheet: React.FC<UserPopoverProps> = ({
  user,
  visible,
  toggleVisibility,
  openDialog,
  triggerRef,
}) => {
  const { formatText } = useLocalization();
  const [showDetails, setShowDetails] = React.useState(false);
  const { config } = useStores();
  const userProfileLink = config.appConfig.userProfileUrl;
  const dyff = useDyffContext();
  const notificationsEnabled = dyff.isFeatureEnabled('EMAIL_NOTIFICATIONS');

  const handleShowDetails = () => {
    setShowDetails((c) => !c);
  };

  const togglePopover = () => {
    setShowDetails(false);
    toggleVisibility();
  };

  return (
    <EGDSLayoutFlex space="four">
      <EGDSLayoutFlexItem className="top-nav-section">
        <EGDSLayoutFlex justifyContent={'end'}>
          <EGDSLayoutPosition type="relative" style={{ border: 'none' }}>
            <EGDSTertiaryButton
              inverse
              size={EGDSButtonSize.MEDIUM}
              style={{ border: visible ? '1px solid white' : 'none' }}
              onClick={togglePopover}
              domRef={triggerRef}
            >
              <EGDSIcon name="account_circle" className="top-nav-section" />
              {`${user?.name}`}
            </EGDSTertiaryButton>
            <EGDSSheetTransition isVisible={visible}>
              <EGDSSheet
                position={{ inline: 'trailing' }}
                width={280}
                isVisible={visible}
                type="popover"
                triggerRef={triggerRef}
                onDismiss={toggleVisibility}
              >
                <EGDSSheetContent>
                  <EGDSText spacing="two" weight={'bold'} size={400} className="initials-style">
                    <span onDoubleClick={handleShowDetails}>{`${user?.initials}`}</span>
                  </EGDSText>
                  <EGDSText spacing="two" weight={'medium'} size={400} align={'center'}>
                    {formatText('layout.topNavBar.userPopoverSheetGreeting', user?.name)}
                  </EGDSText>
                  <EGDSText spacing="two" align={'center'}>
                    {`${user?.primaryEmail}`}
                  </EGDSText>
                  {showDetails && (
                    <>
                      <EGDSText spacing="two">Roles:</EGDSText>
                      <EGDSText>
                        <b>{user.partnerRoles}</b>
                      </EGDSText>
                      <EGDSText spacing="two">
                        Id:
                        <button onClick={() => navigator.clipboard.writeText(user.userId)}>
                          <EGDSIcon name="copy_content" />
                        </button>
                      </EGDSText>
                      <EGDSText>
                        <b>{user.userId}</b>
                      </EGDSText>
                      <EGDSText spacing="two">Type:</EGDSText>
                      <EGDSText>
                        <b>{user.partnerType}</b>
                      </EGDSText>
                    </>
                  )}
                  <EGDSSecondaryButton
                    size={EGDSButtonSize.SMALL}
                    isFullWidth={true}
                    style={{ color: 'black', marginTop: '20px' }}
                    onClick={() => window.open(userProfileLink, '_blank', 'noopener,noreferrer')}
                  >
                    {formatText('layout.topNavBar.userPopoverSheetManageProfileButtonText')}
                  </EGDSSecondaryButton>
                  {notificationsEnabled && (
                    <EGDSTertiaryButton
                      size={EGDSButtonSize.SMALL}
                      isFullWidth={true}
                      style={{ color: 'black', marginTop: '12px' }}
                    >
                      <a href="/notification-settings" style={{ textDecoration: 'none' }}>
                        {formatText('layout.topNavBar.userPopoverSheetNotificationSettingsButtonText')}
                      </a>
                    </EGDSTertiaryButton>
                  )}
                  <EGDSSpacingHr />
                  <EGDSSubheading>
                    {formatText('layout.topNavBar.userPopoverSheetPartnerAccountHeading')}
                  </EGDSSubheading>
                  <EGDSLayoutFlex space="two" className={'partner-account-style'}>
                    <EGDSLayoutFlexItem alignSelf={'center'}>
                      <EGDSIcon name="account_circle" />
                    </EGDSLayoutFlexItem>
                    <EGDSLayoutFlexItem>
                      <EGDSLayoutFlex style={{ display: 'block' }}>
                        <EGDSLayoutFlexItem>
                          <EGDSHeading tag="h6" size={7}>
                            {`${user?.partnerName}`}
                          </EGDSHeading>
                        </EGDSLayoutFlexItem>
                        <EGDSLayoutFlexItem>
                          {!user.adminContext && (
                            <EGDSLink>
                              <a href="/view-and-manage" style={{ textDecoration: 'none' }}>
                                {formatText('layout.topNavBar.userPopoverSheetViewAndManageButtonText')}
                              </a>
                            </EGDSLink>
                          )}
                        </EGDSLayoutFlexItem>
                      </EGDSLayoutFlex>
                    </EGDSLayoutFlexItem>
                  </EGDSLayoutFlex>
                  <EGDSSecondaryButton
                    size={EGDSButtonSize.SMALL}
                    isFullWidth={true}
                    style={{ color: 'black', marginTop: '2rem' }}
                    onClick={openDialog}
                    name="visibility"
                    domRef={triggerRef}
                  >
                    {formatText('layout.topNavBar.userPopoverSheetSwitchPartnerAccountButtonText')}
                  </EGDSSecondaryButton>
                  <EGDSTertiaryButton
                    size={EGDSButtonSize.SMALL}
                    isFullWidth={true}
                    style={{ color: 'black', marginTop: '20px' }}
                  >
                    <a href="/logout" style={{ textDecoration: 'none' }}>
                      {formatText('layout.topNavBar.userPopoverSheetSignOutButtonText')}
                    </a>
                  </EGDSTertiaryButton>
                </EGDSSheetContent>
              </EGDSSheet>
            </EGDSSheetTransition>
          </EGDSLayoutPosition>
        </EGDSLayoutFlex>
      </EGDSLayoutFlexItem>
    </EGDSLayoutFlex>
  );
};
