import React, { useState } from 'react';

import TermsAndConditions from '@common/terms-conditions-dialog';
import { EGDSBanner } from '@egds/react-core';
import { EGDSTertiaryButton } from '@egds/react-core';
import { EGDSIcon } from '@egds/react-core';
import { useLayoutHelper } from '@hooks/use-layout-helper';
import { useStores } from '@hooks/use-stores';
import TopNavBar from '@layout/layout-content/top-nav-bar';
import { ViewContainer } from '@layout/layout-content/view-container';
import { useSwitchToken } from '@shared-ui/control-plane-common';
import { LocalizedText } from '@shared-ui/localization-context';

interface IProps {
  children: React.ReactNode;
  ignorePadding?: boolean;
  fixedFooter?: boolean;
}

export const Layout: React.FC<IProps> = ({ children, ignorePadding, fixedFooter }: IProps) => {
  const { navOpen, setNavOpen } = useLayoutHelper();
  const { user, config } = useStores();
  const { switchToPartnerAccountToken } = useSwitchToken('/eg/auth/custom/authorize');

  const { partnerType } = user;
  const [showTermsConditionsDialog] = useState<boolean>(partnerType !== 'FLIGHT_SPONSORED_LISTING');
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState<boolean>(false);

  const handleAcceptTerms = () => {
    setHasAcceptedTerms(true);
  };

  const exitEmulation = () => {
    switchToPartnerAccountToken(config.appConfig.sponsoredContentPartnerAccountId, '/admn/campaignSearch');
  };

  return (
    <>
      <TopNavBar navOpen={navOpen} setNavOpen={setNavOpen} wizardMode={false} />
      <noscript>
        <div style={{ borderRadius: 0 }}>
          <EGDSBanner theme="error">You need to enable javascript to run this app</EGDSBanner>
        </div>
      </noscript>
      <ViewContainer navOpen={navOpen} setNavOpen={setNavOpen} ignorePadding={ignorePadding} fixedFooter={fixedFooter}>
        {showTermsConditionsDialog && !hasAcceptedTerms && <TermsAndConditions onAccept={handleAcceptTerms} />}
        {user && user.sponsoredContentUser && !user.adminContext && (
          <EGDSTertiaryButton type="button" onClick={() => exitEmulation()}>
            <EGDSIcon name="arrow_back" /> <LocalizedText message="layout.backToAdminPortalText" />
          </EGDSTertiaryButton>
        )}
        {children}
      </ViewContainer>
    </>
  );
};
