import * as React from 'react';

import { gql } from '@apollo/client';
import { EGDSBreadcrumbs } from '@egds/react-core';
import { EGDSButtonSize, EGDSTertiaryButton } from '@egds/react-core';
import { EGDSCard, EGDSCardContentSection } from '@egds/react-core';
import { EGDSIcon, EGDSIconSize } from '@egds/react-core';
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from '@egds/react-core';
import { EGDSSpacing } from '@egds/react-core';
import { EGDSHeading, EGDSText } from '@egds/react-core';
import { EGDSToast } from '@egds/react-core';
import { FragmentComponent } from '@graphql/component-types';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import { SubSubCategory } from '../../../common/__generated__/api/types';
import { ContactForm } from '../internal/contact-form';

export const howToArticleFragment = gql`
  fragment howToArticleFragment on SubSubCategory {
    question
    answer
    backButton {
      primary
    }
    backIcon {
      description
      id
      token
    }
    contactForm {
      title
      secondaryTitle
      subtitle
      secondarySubtitle
      articleMessageButton {
        primary
      }
      firstName {
        label
        placeholder
        validations {
          ... on EGDSMinLengthInputValidation {
            minLength
            errorMessage
          }
        }
      }
      lastName {
        label
        placeholder
        validations {
          ... on EGDSMinLengthInputValidation {
            minLength
            errorMessage
          }
        }
      }
      email {
        label
        placeholder
        validations {
          ... on EGDSRegexInputValidation {
            pattern
            errorMessage
          }
        }
      }
      suppliedEmail {
        label
        placeholder
        validations {
          ... on EGDSRegexInputValidation {
            pattern
            errorMessage
          }
        }
      }
      message {
        label
        placeholder
        validations {
          ... on EGDSMinLengthInputValidation {
            minLength
            errorMessage
          }
        }
      }
      contactIcon {
        description
        id
        token
      }
      sendButton {
        primary
      }
      cancelButton {
        primary
      }
      attachmentLink {
        text
        icon {
          description
          id
          token
        }
      }
      attachmentError {
        message
        icon {
          description
          id
          token
        }
      }
    }
  }
`;

interface ISubSubCategoryProps {
  data: SubSubCategory;
  parentCategory: string;
  subCategory: string;
  onBack: () => void;
}
export const HowToArticle: FragmentComponent<ISubSubCategoryProps, SubSubCategory> = ({
  data,
  parentCategory,
  subCategory,
  onBack,
}) => {
  const crumbs = [
    <EGDSText key={parentCategory} inline={true}>
      {parentCategory}
    </EGDSText>,
    <EGDSText key={subCategory} inline={true}>
      {subCategory}
    </EGDSText>,
    <EGDSText key={data.question} inline={true}>
      {data.question}
    </EGDSText>,
  ];

  const [showContactForm, setShowContactForm] = React.useState(false);
  const [contactFormSubmitted, setContactFormSubmitted] = React.useState(false);

  const handleSuccess = (mutationState) => {
    setContactFormSubmitted(mutationState);
  };

  const handleCancel = () => {
    setShowContactForm(false);
  };

  const [show, setShow] = React.useState(false);
  const onCloseRef = React.useRef(null);
  const [successResponse, setSuccessResponse] = React.useState('');

  const renderToast = (successMsg) => {
    setShow(true);
    setSuccessResponse(successMsg);
  };

  React.useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShow(false);
      }, 4000);
    }
  }, [show]);

  const contactForm = (
    <div style={{ marginBlockStart: '16px' }}>
      <EGDSCard padded>
        <EGDSCardContentSection>
          <EGDSLayoutFlex space={'three'} blockSize={'full_size'} alignItems={'center'}>
            <EGDSLayoutFlexItem>
              <EGDSIcon name={data.contactForm.contactIcon.token} />
            </EGDSLayoutFlexItem>
            <EGDSLayoutFlex direction={'column'} alignItems={'start'}>
              <EGDSLayoutFlexItem>
                <EGDSText weight={'bold'}>{data.contactForm.title}</EGDSText>
              </EGDSLayoutFlexItem>
              <EGDSSpacing padding={{ blockstart: 'one' }}>
                <EGDSLayoutFlexItem>
                  <EGDSText>{data.contactForm.subtitle}</EGDSText>
                </EGDSLayoutFlexItem>
              </EGDSSpacing>
            </EGDSLayoutFlex>
          </EGDSLayoutFlex>
        </EGDSCardContentSection>
        <ContactForm
          data={data}
          parentCategory={parentCategory}
          subCategory={subCategory}
          question={data.question}
          onBack={onBack}
          handleCancel={handleCancel}
          handleSuccess={handleSuccess}
          renderToast={renderToast}
        />
      </EGDSCard>
    </div>
  );

  const needMoreHelpCard = (
    <EGDSSpacing margin={{ blockstart: 'four' }}>
      <EGDSCard padded style={{ padding: '24px 24px' }}>
        <EGDSCardContentSection padded>
          <EGDSLayoutFlex space={'three'} blockSize={'full_size'} alignItems={'center'}>
            <EGDSLayoutFlexItem>
              <EGDSIcon name={data.contactForm.contactIcon.token} size={EGDSIconSize.LARGE} />
            </EGDSLayoutFlexItem>
            <EGDSLayoutFlex direction={'column'} alignItems={'start'} space={'three'}>
              <EGDSLayoutFlexItem minWidth={'1x'}>
                <EGDSText size={400} weight={'bold'}>
                  {data.contactForm.title}
                </EGDSText>
              </EGDSLayoutFlexItem>
              <EGDSLayoutFlexItem>
                <EGDSText>{data.contactForm.subtitle}</EGDSText>
              </EGDSLayoutFlexItem>
              <EGDSLayoutFlexItem>
                <EGDSTertiaryButton
                  alignLabelToContent="start"
                  size={EGDSButtonSize.SMALL}
                  className="need-more-help-button"
                  onClick={() => setShowContactForm(true)}
                >
                  {data.contactForm.articleMessageButton.primary}
                </EGDSTertiaryButton>
              </EGDSLayoutFlexItem>
            </EGDSLayoutFlex>
          </EGDSLayoutFlex>
        </EGDSCardContentSection>
      </EGDSCard>
    </EGDSSpacing>
  );

  return (
    <>
      <EGDSCard padded>
        <EGDSToast onCloseRef={onCloseRef} show={show} autoHide={false}>
          {successResponse}
        </EGDSToast>
        <EGDSCardContentSection padded>
          <EGDSTertiaryButton onClick={onBack}>
            <EGDSIcon name={data.backIcon.token} />
            <EGDSText size={500}>{data.backButton.primary}</EGDSText>
          </EGDSTertiaryButton>
          <EGDSSpacing padding={{ inlinestart: 'three' }}>
            <EGDSBreadcrumbs crumbs={crumbs} hasDynamicInlineCrumbs={true} />
          </EGDSSpacing>
          <div style={{ padding: '0px 12px 12px 12px' }}>
            <EGDSSpacing margin={{ blockend: 'four' }}>
              <EGDSHeading size={4}>{data.question}</EGDSHeading>
            </EGDSSpacing>
            <EGDSSpacing margin={{ blockstart: 'two' }}>
              <ReactMarkdown
                className="markdown-content"
                rehypePlugins={[rehypeRaw]}
                components={{
                  a: ({ ...props }) => (
                    <a className="EGDS-link" style={{ color: '#1668e3' }} target="_blank" {...props} />
                  ),
                  br: () => <div style={{ marginBottom: '8px' }}></div>,
                }}
              >
                {data.answer}
              </ReactMarkdown>
            </EGDSSpacing>
          </div>
        </EGDSCardContentSection>
      </EGDSCard>
      {!showContactForm ? needMoreHelpCard : null}
      {showContactForm === true && contactFormSubmitted === false ? contactForm : null}
    </>
  );
};
HowToArticle.fragment = howToArticleFragment;
HowToArticle.displayName = 'How to article';
