import React, { SetStateAction, useEffect, useRef } from 'react';

import Banner from '@common/banner';
import PageError from '@common/error-page';
import { EGDSCard, EGDSCardContentSection } from '@egds/react-core';
import { EGDSDialog, EGDSDialogContent } from '@egds/react-core';
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from '@egds/react-core';
import { EGDSLoader } from '@egds/react-core';
import { EGDSLineSkeleton } from '@egds/react-core';
import { EGDSParagraph, EGDSHeading } from '@egds/react-core';
import { useLayoutHelper } from '@hooks/use-layout-helper';
import { useStores } from '@hooks/use-stores';
import { SideNavBar } from '@layout/layout-content/side-nav-bar';
import { useDialog } from '@shared-ui/dialog-context';
import { observer } from 'mobx-react';
import { ErrorBoundary } from 'react-error-boundary';

interface IProps {
  navOpen: boolean;
  setNavOpen: React.Dispatch<SetStateAction<boolean>>;
  children: React.ReactNode;
  ignorePadding?: boolean;
  fixedFooter?: boolean;
}

export const ViewContainer: React.FC<IProps> = observer(
  ({ navOpen, setNavOpen, children, ignorePadding, fixedFooter }: IProps) => {
    const { user, layout, banner } = useStores();
    const { isMobile } = useLayoutHelper();
    const dialogTriggerRef = useRef();
    const [isDialogOpen, dialogActions, DialogComponent] = useDialog('loading-overlay');

    let navContainerClass = `nav-container`;
    if (isMobile) {
      navContainerClass += ` nav-container-mobile`;
    }
    if (navOpen) {
      navContainerClass += ' nav-open';
    }

    const closeNavIfMobile = () => {
      if (isMobile) {
        setNavOpen(false);
      }
    };

    useEffect(() => {
      const interval = setInterval(() => {
        fetch('/session-check')
          .then((r) => r.text())
          .then((r) => {
            if (r == 'false') {
              window.location.reload();
            }
          });
      }, 1000 * 60);

      return () => {
        clearInterval(interval);
      };
    }, []);

    useEffect(() => {
      if (layout.loadingOverlay) {
        dialogActions.openDialog();
      } else {
        dialogActions.closeDialog();
      }
    }, [layout.loadingOverlay]);

    return (
      <div className={navContainerClass}>
        <DialogComponent>
          <EGDSDialog
            ariaLabel="overlay-loader"
            returnFocusOnClose={true}
            dialogShow={isDialogOpen}
            triggerRef={dialogTriggerRef}
          >
            <DialogLoader header={layout.loadingOverlayHeader} body={layout.loadingOverlayBody} />
          </EGDSDialog>
        </DialogComponent>
        <SideNavBar navOpen={navOpen} setNavOpen={setNavOpen} />
        <main role="main" className={`page-container ${fixedFooter ? 'fixed-footer' : ''}`}>
          {banner && <Banner />}
          <span role="presentation" className="nav-drawer-mask" onClick={closeNavIfMobile} />
          <div style={{ maxWidth: '1200px' }}>
            <div style={ignorePadding ? {} : { padding: '1.5rem' }}>
              <EGDSLayoutFlex space="six" direction="column">
                <EGDSLayoutFlexItem style={{ flexGrow: 10 }}>
                  <div>
                    {(!user || !user.actingUserId) && (
                      <PageError
                        error={Error('Something went wrong attempting to load user data')}
                        resetErrorBoundary={null}
                      />
                    )}
                    {layout.loadingMainPageBody && (
                      <>
                        <PageLoader />
                      </>
                    )}
                    {user && user.actingUserId && (
                      <ErrorBoundary FallbackComponent={PageError}>{children}</ErrorBoundary>
                    )}
                  </div>
                </EGDSLayoutFlexItem>
              </EGDSLayoutFlex>
            </div>
          </div>
        </main>
      </div>
    );
  },
);

interface IDialogLoaderProps {
  header?: string;
  body?: string;
}

const DialogLoader: React.FC<IDialogLoaderProps> = ({ header, body }: IDialogLoaderProps) => {
  return (
    <EGDSDialogContent key="dialogContent2">
      <EGDSLayoutFlex space="two" direction="column">
        <>
          <EGDSLayoutFlexItem alignSelf="center">
            <EGDSLoader />
          </EGDSLayoutFlexItem>
          {header && (
            <EGDSLayoutFlexItem alignSelf="center">
              <EGDSHeading tag="h2" size={5} key="dialogHeading">
                {header}
              </EGDSHeading>
            </EGDSLayoutFlexItem>
          )}
          {body && (
            <EGDSLayoutFlexItem alignSelf="center">
              <EGDSParagraph key="dialogParagraph" size={2}>
                {body}
              </EGDSParagraph>
            </EGDSLayoutFlexItem>
          )}
        </>
      </EGDSLayoutFlex>
    </EGDSDialogContent>
  );
};

const PageLoader = () => {
  return (
    <EGDSCard padded={false}>
      <EGDSCardContentSection>
        <EGDSLineSkeleton />
      </EGDSCardContentSection>
      <EGDSCardContentSection>
        <EGDSLineSkeleton animation={true} type="secondary" />
      </EGDSCardContentSection>
    </EGDSCard>
  );
};
