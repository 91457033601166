import React, { useEffect } from 'react';

import { EGDSIcon } from '@egds/react-core';
import { EGDSLayoutGridItem } from '@egds/react-core';
import { EGDSLayoutPosition } from '@egds/react-core';
import { EGDSSheet, EGDSSheetContent, EGDSSheetTransition } from '@egds/react-core';
import useClickOutside from '@hooks/use-click-outside';

interface ISidePanelProps {
  collapse: boolean;
  content: React.ReactNode;
  marginTop?: string;
}

export const SidePanel: React.FC<ISidePanelProps> = (props) => {
  const { collapse, content, marginTop } = props;

  const [showSidePanel, setShowSidePanel] = React.useState(false);
  const sheetTriggerRef = React.useRef();
  const clickRef = React.useRef();

  useClickOutside(clickRef, () => {
    if (showSidePanel) {
      setShowSidePanel(false);
    }
  });

  useEffect(() => {
    if (!collapse) {
      setShowSidePanel(false);
    }
  }, [collapse]);

  return (
    <>
      {collapse && (
        <div className="side-sheet" ref={clickRef}>
          <EGDSLayoutPosition type={'absolute'} style={{ bottom: 100, right: 25 }}>
            <div ref={sheetTriggerRef} className="pointer-cursor" onClick={() => setShowSidePanel((c) => !c)}>
              <EGDSIcon name="info" spotlight={'info'} id="insight-toggle-icon" />
            </div>
            <EGDSSheetTransition isVisible={showSidePanel}>
              <EGDSSheet
                position={{ inline: 'trailing', block: 'leading' }}
                isVisible={showSidePanel}
                liveAnnounceString="Popover Sheet has opened"
                type="popover"
                triggerRef={sheetTriggerRef}
                width={360}
              >
                <EGDSSheetContent>{content}</EGDSSheetContent>
              </EGDSSheet>
            </EGDSSheetTransition>
          </EGDSLayoutPosition>
        </div>
      )}
      {!collapse && (
        <EGDSLayoutGridItem style={{ maxWidth: 400, minWidth: 300 }}>
          <div style={{ padding: '1.5rem', paddingLeft: '0.25rem', marginTop: marginTop }}>{content}</div>
        </EGDSLayoutGridItem>
      )}
    </>
  );
};
