import * as React from 'react';

import { EGDSIcon, EGDSLayoutFlex, EGDSLayoutFlexItem, EGDSTertiaryButton, EGDSText } from '@egds/react-core';

import { HELP_SUPPORT_MAX_TOTAL_SIZE_MB } from '../../../constants';

interface AttachmentListProps {
  selectedFiles: FileList | null;
  setSelectedFiles: React.Dispatch<React.SetStateAction<FileList | null>>;
  setFileSizeError: React.Dispatch<React.SetStateAction<boolean>>;
}

const AttachmentList: React.FC<AttachmentListProps> = ({ selectedFiles, setSelectedFiles, setFileSizeError }) => {
  const handleRemoveFile = (index: number) => {
    if (selectedFiles) {
      const newFiles = Array.from(selectedFiles).filter((_, i) => i !== index);
      const dataTransfer = new DataTransfer();
      newFiles.forEach((file) => dataTransfer.items.add(file));
      setSelectedFiles(dataTransfer.files);

      const totalSize = newFiles.reduce((acc, file) => acc + file.size, 0);
      const isFileSizeError = totalSize > HELP_SUPPORT_MAX_TOTAL_SIZE_MB;
      setFileSizeError(isFileSizeError);
    }
  };

  const formatFileSize = (size: number) => {
    return size > 1024 * 1024 ? `${(size / (1024 * 1024)).toFixed(2)} MB` : `${(size / 1024).toFixed(2)} KB`;
  };

  return (
    <div style={{ marginTop: '16px' }}>
      {selectedFiles &&
        Array.from(selectedFiles).map((file, index) => (
          <EGDSLayoutFlex
            key={index}
            justifyContent="space-between"
            alignItems="center"
            style={{ marginBottom: '8px' }}
          >
            <EGDSLayoutFlexItem>
              <EGDSText>
                {file.name} ({formatFileSize(file.size)})
              </EGDSText>
            </EGDSLayoutFlexItem>
            <EGDSLayoutFlexItem>
              <EGDSTertiaryButton aria-label="remove" onClick={() => handleRemoveFile(index)}>
                <EGDSIcon name="close" />
              </EGDSTertiaryButton>
            </EGDSLayoutFlexItem>
          </EGDSLayoutFlex>
        ))}
    </div>
  );
};

export default AttachmentList;
