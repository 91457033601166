import React, { useEffect, useState } from 'react';

import { EGDSTertiaryButton } from '@egds/react-core';
import { EGDSPrimaryButton } from '@egds/react-core';
import { EGDSCheckbox } from '@egds/react-core';
import { EGDSDialog, EGDSDialogContent } from '@egds/react-core';
import { EGDSLayoutFlex } from '@egds/react-core';
import { EGDSSpacing } from '@egds/react-core';
import { EGDSHeading, EGDSParagraph } from '@egds/react-core';
import { useLocalization } from '@shared-ui/localization-context';
import { getCurrentDateTimeUTC } from '@utils/date-utils';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import { fetchTermsConditionsMetadata } from '../../utils/terms-conditions-util';

const TermsAndConditions: React.FC<{ onAccept: () => void }> = ({ onAccept }) => {
  const { formatText } = useLocalization();
  const [isChecked, setIsChecked] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [cdnTermsConditionsFileUrl, setCdnTermsConditionsFileUrl] = useState('');
  const [cdnTermsConditionsVersion, setCdnTermsConditionsVersion] = useState('');
  const [profileTermsConditions, setProfileTermsConditions] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const getMetadata = async () => {
      try {
        const cdnTermsConditons = await fetchTermsConditionsMetadata();
        setCdnTermsConditionsVersion(cdnTermsConditons.version);
        setCdnTermsConditionsFileUrl(cdnTermsConditons.fileUrl);

        const profileTermsConditions = await handleProfileTermsConditionsCheck(cdnTermsConditons.version);
        //setProfileTermsConditions helps us know it's a PATCH operation versus a POST operation since a Profile already exists
        setProfileTermsConditions(profileTermsConditions);
      } catch (error) {
        console.error("Error fetching the user's Terms and Conditions data:", error);
      }
    };
    getMetadata();
  }, []);

  const handleLogout = () => {
    history.push('/logout');
  };

  const handleProfileTermsConditionsCheck = async (cdnVersion) => {
    try {
      const { data } = await axios.get('/api/get-terms-conditions-acceptance');
      if (!data?.version || data?.version !== cdnVersion) {
        setShowDialog(true);
      }
      if (data?.version) {
        return data;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error in handleProfileTermsConditionsCheck:', error);
    }
  };

  // Accept terms and conditions
  // This function is called when the user accepts the terms and conditions
  // It sends a POST request to the server to update the user's profile with the new terms and conditions
  // It also updates the local storage with the new terms and conditions version and timestamp
  const handleAcceptTermsConditions = async (versionInput, utcTimestampInput) => {
    const utcTimestamp = utcTimestampInput ?? getCurrentDateTimeUTC();
    try {
      //if there is an existing profile version, call patch
      if (profileTermsConditions != null && profileTermsConditions != '') {
        await Promise.all([
          updateAcceptTermsConditions('/version', versionInput),
          updateAcceptTermsConditions('/timestamp', utcTimestamp),
        ]);
      } else {
        await axios.post('/api/accept-terms-conditions', {
          timestamp: utcTimestamp,
          version: versionInput,
        });
      }
      onAccept();
    } catch (error) {
      console.error('Error in handleAcceptTermsConditions:', error);
    }
  };

  // Update accept terms and conditions
  // This function is called when the user has already accepted the terms and conditions
  // It sends a POST request to the server to update the user's profile with the new terms and conditions
  const updateAcceptTermsConditions = async (pathInput, patchValue) => {
    try {
      await axios.post('/api/update-terms-conditions', {
        path: pathInput,
        value: patchValue,
      });
    } catch (error) {
      console.error('Error in updateAcceptTermsConditions:', error);
    }
  };

  if (!showDialog) return null;

  const tncLinkContext = formatText('termsAndConditions.cardLinkText', cdnTermsConditionsFileUrl);

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        background: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      <EGDSDialog sheetType="centered" heightAuto dialogShow closeCallback={() => {}}>
        <EGDSDialogContent>
          <EGDSSpacing>
            <EGDSHeading>{formatText('termsAndConditions.cardHeading')}</EGDSHeading>
          </EGDSSpacing>
          <EGDSSpacing padding={{ blockend: 'two' }}>
            <EGDSParagraph>
              <span dangerouslySetInnerHTML={{ __html: tncLinkContext }} />
              {formatText('termsAndConditions.cardLinkText')}
            </EGDSParagraph>
          </EGDSSpacing>
          <EGDSLayoutFlex alignItems="center">
            <EGDSCheckbox
              id="terms-and-conditions-checkbox"
              checked={isChecked}
              label={formatText('termsAndConditions.cardCheckboxText')}
              onChange={(e) => setIsChecked(e.target.checked)}
            >
              {formatText('termsAndConditions.cardCheckboxText')}
            </EGDSCheckbox>
          </EGDSLayoutFlex>
          <EGDSSpacing margin={{ block: 'two' }}>
            <EGDSPrimaryButton
              onClick={() => handleAcceptTermsConditions(cdnTermsConditionsVersion, getCurrentDateTimeUTC())}
              disabled={!isChecked}
              isFullWidth
            >
              {formatText('termsAndConditions.cardAcceptButtonText')}
            </EGDSPrimaryButton>
          </EGDSSpacing>
          <EGDSSpacing margin={{ blockstart: 'one' }}>
            <EGDSTertiaryButton onClick={handleLogout} isFullWidth>
              {formatText('termsAndConditions.cardLogoutButtonText')}
            </EGDSTertiaryButton>
          </EGDSSpacing>
        </EGDSDialogContent>
      </EGDSDialog>
    </div>
  );
};

export default TermsAndConditions;
