import * as React from 'react';

import { EGDSLayoutFlex } from '@egds/react-core';
import { EGDSText } from '@egds/react-core';
import { useLocalization } from '@shared-ui/localization-context';

const HowBudgetingWorks = () => {
  const { formatText } = useLocalization();

  const paragraph1 = formatText('common.sideInfoSheets.howBudgetsWorkParagraph1');
  const paragraph2 = formatText('common.sideInfoSheets.howBudgetsWorkParagraph2');

  return (
    <EGDSLayoutFlex direction="column" space="four">
      <EGDSText>
        <span dangerouslySetInnerHTML={{ __html: paragraph1 }} />
      </EGDSText>
      <EGDSText>
        <span dangerouslySetInnerHTML={{ __html: paragraph2 }} />
      </EGDSText>
    </EGDSLayoutFlex>
  );
};

export default HowBudgetingWorks;
