import React from 'react';

import { EGDSPrimaryButton } from '@egds/react-core';
import { EGDSCard, EGDSCardBackgroundTheme } from '@egds/react-core';
import { EGDSIcon } from '@egds/react-core';
import { EGDSSpacing } from '@egds/react-core';
import { EGDSHeading, EGDSText } from '@egds/react-core';
import { useLocalization } from '@shared-ui/localization-context';

export const ErrorCard: React.FC<{
  error: Error;
  theme: EGDSCardBackgroundTheme;
  message?: string;
  reloadClick?: () => void;
}> = (props) => {
  const { error, message, theme } = props;
  const { formatText } = useLocalization();
  return (
    <EGDSCard backgroundTheme={theme}>
      <EGDSSpacing margin={{ blockstart: 'twelve', blockend: 'three' }} padding={{ inline: 'six' }}>
        {message ? (
          <EGDSHeading tag="h6" size={6}>
            {message}
          </EGDSHeading>
        ) : (
          <>
            {error.message && (
              <EGDSHeading tag="h6" size={6}>
                [{error?.message}]
              </EGDSHeading>
            )}
          </>
        )}
      </EGDSSpacing>
      <EGDSSpacing padding={{ inline: 'six' }}>
        <EGDSText>
          {formatText('common.errorCardMessage1')}
          <br />
          {formatText('common.errorCardMessage2')}
        </EGDSText>
      </EGDSSpacing>
      <EGDSSpacing margin={{ inlinestart: 'six', block: 'three' }}>
        <EGDSPrimaryButton
          type="button"
          onClick={props.reloadClick ? () => props.reloadClick() : () => window.location.reload()}
        >
          {formatText('common.errorCardRefreshText')}
          <EGDSIcon name="refresh" />
        </EGDSPrimaryButton>
      </EGDSSpacing>
    </EGDSCard>
  );
};
