import React from 'react';

import { EGDSLayoutFlex, EGDSLayoutFlexItem } from '@egds/react-core';
import { EGDSView } from '@egds/react-core';
import { FallbackProps } from 'react-error-boundary';

import { ErrorCard } from './error-card';

interface IProps extends FallbackProps {}

const ErrorPage = ({ error }: IProps) => {
  return (
    <main role="main" className="page-container">
      <EGDSView className="page-container">
        <EGDSLayoutFlex alignItems="center" justifyContent="center">
          <EGDSLayoutFlexItem alignSelf="center">
            <ErrorCard error={error} theme="secondary" />
          </EGDSLayoutFlexItem>
        </EGDSLayoutFlex>
      </EGDSView>
    </main>
  );
};

export default ErrorPage;
