import React, { useState, useEffect } from 'react';

import { EGDSButtonSize, EGDSTertiaryButton } from '@egds/react-core';
import { EGDSIcon, EGDSIconSize } from '@egds/react-core';
import { PositionType, EGDSMenu, EGDSMenuContainer, EGDSMenuList, EGDSMenuTrigger } from '@egds/react-core';
import { useBexApi } from '@shared-ui/bex-api-context';
import axios from 'axios';

import { LANGUAGES } from '../../constants';

const LanguagePreferenceMenu: React.FC = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const { context } = useBexApi();

  useEffect(() => {
    const getLanguagePreference = async () => {
      const languagePreference = context.locale;
      const language = LANGUAGES.find((lang) => lang.locale === languagePreference);
      setSelectedIndex(LANGUAGES.indexOf(language));
      setSelectedLanguage(language?.code ?? 'EN');
    };
    getLanguagePreference();
  }, []);

  const toggleMenuDisplay = () => {
    setMenuOpen((prevMenuOpen) => !prevMenuOpen);
  };

  const handleClick = () => {
    toggleMenuDisplay();
  };

  const onMenuItemClick = async (newSelectedIndex, language) => {
    if (newSelectedIndex !== selectedIndex) {
      setSelectedIndex(newSelectedIndex);
      setSelectedLanguage(language.code);
      await updateLanguagePreference(language.locale);
    }
    toggleMenuDisplay();
    window.location.reload();
  };

  const updateLanguagePreference = async (patchValue) => {
    try {
      await axios.post('/api/update-language-preference', {
        path: '/preferred_language',
        value: patchValue,
      });
    } catch (error) {
      console.error('Error in updateLanguagePreference:', error);
    }
  };

  const menuItemsWithIcons = LANGUAGES.map((language, index) => ({
    attributes: {
      role: 'menuitemcheckbox',
      tabindex: 0,
    },
    className: 'uitk-list-item',
    onClick: () => onMenuItemClick(index, language),
    label: <span style={{ marginRight: '80px' }}>{language.label}</span>,
    typeAnchor: false,
    selected: selectedIndex === index,
  }));

  return (
    <EGDSMenu id="languagePreferenceMenu" isOpen={menuOpen} onTriggerClick={handleClick}>
      <EGDSMenuTrigger>
        <EGDSTertiaryButton inverse size={EGDSButtonSize.MEDIUM}>
          <EGDSIcon name={'language'} size={EGDSIconSize.MEDIUM} />
          {selectedLanguage}
        </EGDSTertiaryButton>
      </EGDSMenuTrigger>
      <EGDSMenuContainer
        position={PositionType.LEFT}
        width={250}
        autoPosition={true}
        intersectionObserverRoot=".menu-intersection-observer"
      >
        <EGDSMenuList items={menuItemsWithIcons} />
      </EGDSMenuContainer>
    </EGDSMenu>
  );
};

export default LanguagePreferenceMenu;
