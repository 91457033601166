import * as React from 'react';

import { EGDSFigure, EGDSFigureAspectRatioType, EGDSImage } from '@egds/react-core';
import { EGDSLayoutFlex } from '@egds/react-core';
import { EGDSText, EGDSHeading } from '@egds/react-core';
import { useBexApi } from '@shared-ui/bex-api-context';
import { useLocalization } from '@shared-ui/localization-context';
import { CDN_URL } from 'src/constants';

const HowCampaignsWork = () => {
  const { formatText } = useLocalization();
  const { context: locale } = useBexApi();

  const paragraph1 = formatText('common.sideInfoSheets.howCampaignsWorkParagraph1');
  const paragraph2 = formatText('common.sideInfoSheets.howCampaignsWorkParagraph2');
  const paragraph3 = formatText('common.sideInfoSheets.howCampaignsWorkParagraph3');
  const paragraph4 = formatText('common.sideInfoSheets.howCampaignsWorkParagraph4');
  const exampleParagraph = formatText('common.sideInfoSheets.howCampaignsWorkExampleParagraph');

  return (
    <EGDSLayoutFlex direction="column" space="four">
      <EGDSText>
        <span dangerouslySetInnerHTML={{ __html: paragraph1 }} />
      </EGDSText>
      <EGDSText>
        <span dangerouslySetInnerHTML={{ __html: paragraph2 }} />
      </EGDSText>
      <EGDSText>
        <span dangerouslySetInnerHTML={{ __html: paragraph3 }} />
      </EGDSText>
      <EGDSText>
        <span dangerouslySetInnerHTML={{ __html: paragraph4 }} />
      </EGDSText>
      <EGDSText>
        <EGDSHeading>{formatText('common.sideInfoSheets.howCampaignsWorkExampleHeading')}</EGDSHeading>
        <span dangerouslySetInnerHTML={{ __html: exampleParagraph }} />
      </EGDSText>
      <EGDSFigure
        imageFit="contain"
        ratio={EGDSFigureAspectRatioType.R1_1}
        style={{ marginTop: '-5rem', marginBottom: '-5rem' }}
      >
        <EGDSImage src={`${CDN_URL}/images/sample-campaign/v3/${locale.locale}_sample_campaign.png`} />
      </EGDSFigure>
    </EGDSLayoutFlex>
  );
};

export default HowCampaignsWork;
