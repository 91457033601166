import * as React from 'react';

import { FetchResult, useMutation } from '@apollo/client';
import { EGDSBanner, EGDSLink, EGDSLoader } from '@egds/react-core';
import { EGDSPrimaryButton, EGDSSecondaryButton } from '@egds/react-core';
import { EGDSCardContentSection } from '@egds/react-core';
import { EGDSIcon } from '@egds/react-core';
import { EGDSTextArea, EGDSTextInput } from '@egds/react-core';
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from '@egds/react-core';
import { EGDSLayoutGrid, EGDSLayoutGridItem } from '@egds/react-core';
import { EGDSHeading, EGDSText } from '@egds/react-core';
import { CREATE_SALESFORCE_CASE_MUTATION } from '@graphql/mutations';
import { useStores } from '@hooks/use-stores';
import { useBexApi } from '@shared-ui/bex-api-context';
import { useLocalization } from '@shared-ui/localization-context';
import { SalesforceCaseErrorResponse, SalesforceCaseInputModelInput } from 'src/common/__generated__/api/types';

import AttachmentList from './attachment-list';
import { Mutation, SubSubCategory, EgdsRegexInputValidation } from '../../../common/__generated__/api/types';
import { HELP_SUPPORT_MAX_TOTAL_SIZE_MB, MAX_NUMBER_OF_ATTACHMENTS } from '../../../constants';

interface IContactFormProps {
  data: SubSubCategory;
  parentCategory: string;
  subCategory: string;
  question: string;
  onBack?: () => void;
  handleCancel?: () => void;
  handleSuccess?: (mutationState: boolean) => void;
  renderToast?: (successMsg: string) => void;
}

const ContactForm: React.FC<IContactFormProps> = ({
  data,
  parentCategory,
  subCategory,
  question,
  handleCancel,
  handleSuccess,
  renderToast,
}) => {
  const { user } = useStores();
  const { context } = useBexApi();

  const defaultContactForm: SalesforceCaseInputModelInput = {
    suppliedFirstName: user.name.split(' ')[0],
    suppliedLastName: user.name.split(' ')[1],
    suppliedEmail: user.primaryEmail,
    ccEmail: '',
    description: '',
    partnerAccountId: String(user.partnerAccountId),
    egUserId: user.userId,
    subject: `${parentCategory}/${subCategory}/${question}`,
    partnerAccountName: user.partnerName,
    attachments: [],
  };

  const [createSalesforceCaseMutation, { loading }] = useMutation(CREATE_SALESFORCE_CASE_MUTATION, {
    variables: { context: context },
  });

  const { formatText } = useLocalization();
  const [successResponse, setSuccessResponse] = React.useState('');
  const [errorResponse, setErrorResponse] = React.useState<SalesforceCaseErrorResponse>();

  const [validMessage, setValidMessage] = React.useState(true);
  const [validFirstName, setValidFirstName] = React.useState(true);
  const [validLastName, setValidLastName] = React.useState(true);
  const [validEmail, setValidEmail] = React.useState(true);
  const [validCCEmail, setValidCCEmail] = React.useState(true);
  const [showError, setShowError] = React.useState(false);
  const [selectedFiles, setSelectedFiles] = React.useState<FileList | null>(null);
  const [isUploadingFiles, setIsUploadingFiles] = React.useState(false);
  const [fileSizeError, setFileSizeError] = React.useState(false);

  const createSalesforceCase = async () => {
    const isMessageValid = validateMessage();
    if (!isMessageValid) {
      setShowError(true);
      return;
    }
    try {
      const response = await createSalesforceCaseMutation({
        variables: {
          inputForm: {
            ...contactForm,
          },
        },
      });
      handleCreateSalesforceCaseResponse(response);
    } catch (error) {
      // Handle errors when there is not a response from the server like network errors
      console.error('Error in createSalesforceCase mutation', error);
      setErrorResponse({
        __typename: 'SalesforceCaseErrorResponse',
        message: formatText('common.errorPage.errorTitle'),
        errorMessage: formatText('helpAndSupport.error.sendAgain'),
      });
    }
  };

  const handleCreateSalesforceCaseResponse = async (response: FetchResult<Mutation>) => {
    if (response.errors || response.data.createSalesforceCase.errorResponse) {
      setErrorResponse(response.data.createSalesforceCase.errorResponse);
      console.error('Error in createSalesforceCase response', response.errors);
    } else {
      setSuccessResponse(response.data.createSalesforceCase.successResponse.message);
      setErrorResponse(null);
      if (handleSuccess) {
        handleSuccess(true);
      }
    }
  };

  React.useEffect(() => {
    if (successResponse) {
      renderToast(successResponse);
    }
  }, [successResponse]);

  const [contactForm, setContactForm] = React.useState<SalesforceCaseInputModelInput>(defaultContactForm);

  const updateContactForm = (contactForm: Partial<SalesforceCaseInputModelInput>) => {
    setContactForm((prevForm) => ({ ...prevForm, ...contactForm }));
    if (contactForm.description !== undefined) {
      setValidMessage(true);
      setShowError(false);
    }
  };

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFirstName = e.target.value;
    updateContactForm({ suppliedFirstName: newFirstName });
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newLastName = e.target.value;
    updateContactForm({ suppliedLastName: newLastName });
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    updateContactForm({ suppliedEmail: newEmail });
  };

  const handleCCEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const ccEmail = e.target.value;
    updateContactForm({ ccEmail: ccEmail });
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newMessage = e.target.value;
    updateContactForm({ description: newMessage });
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = e.target;
    const fileList = fileInput.files;

    if (!fileList) {
      return;
    }
    const limitedAttachments = getLimitedAttachments(fileList, MAX_NUMBER_OF_ATTACHMENTS);
    setSelectedFiles(limitedAttachments);

    let attachments = [];
    let totalSize = 0;

    if (limitedAttachments) {
      const formData = new FormData();
      for (const file of limitedAttachments) {
        formData.append('files', file);
        totalSize += file.size;
      }

      if (totalSize > HELP_SUPPORT_MAX_TOTAL_SIZE_MB) {
        setFileSizeError(true);
      } else {
        setFileSizeError(false);
        setIsUploadingFiles(true);

        try {
          const response = await fetch('/helpSupportUpload', {
            method: 'POST',
            body: formData,
          });

          if (response.ok) {
            const result = await response.json();
            console.log('File uploaded successfully:', result);
            attachments = result.map((file) => ({
              fileName: file.key || file.Key,
              url: file.key || file.Key,
            }));
            // Filter out any invalid entries
            attachments = attachments.filter((attachment) => attachment.fileName && attachment.url);
          } else {
            console.error('File upload failed:', response.statusText);
          }
        } catch (error) {
          console.error('Error uploading file:', error);
        } finally {
          setIsUploadingFiles(false);
        }
      }
    }

    if (contactForm) {
      setContactForm((prevForm) => ({
        ...prevForm,
        attachments,
      }));
    }

    fileInput.value = '';
  };

  const validateFirstName = () => {
    if (contactForm.suppliedFirstName.length === 0) {
      setValidFirstName(false);
    } else {
      setValidFirstName(true);
    }
  };

  const validateLastName = () => {
    if (contactForm.suppliedLastName.length === 0) {
      setValidLastName(false);
    } else {
      setValidLastName(true);
    }
  };

  const validateEmail = () => {
    const emailPattern = data.contactForm.email.validations[0] as EgdsRegexInputValidation;
    const emailRegexCheck = contactForm.suppliedEmail?.match(emailPattern.pattern);
    if (!emailRegexCheck) {
      setValidEmail(false);
    } else {
      setValidEmail(true);
    }
  };

  const validatecCEmail = () => {
    const emailPattern = data.contactForm.suppliedEmail.validations[0] as EgdsRegexInputValidation;
    const emailRegexCheck = contactForm.ccEmail?.match(emailPattern.pattern);
    if (contactForm.ccEmail.length > 0 && contactForm.ccEmail !== defaultContactForm.ccEmail && !emailRegexCheck) {
      setValidCCEmail(false);
    } else {
      setValidCCEmail(true);
    }
  };

  const validateMessage = () => {
    if (contactForm.description.length === 0) {
      setValidMessage(false);
      return false;
    } else {
      setValidMessage(true);
      return true;
    }
  };

  const validateError = () => {
    if (contactForm.description.length === 0) {
      setShowError(false);
      return false;
    } else {
      setShowError(true);
      return true;
    }
  };

  const getLimitedAttachments = (fileList: FileList, sliceSize: number): FileList => {
    const fileArray = Array.from(fileList);
    const limitedFileArray = fileArray.slice(0, sliceSize);
    const dataTransfer = new DataTransfer();
    limitedFileArray.forEach((file) => dataTransfer.items.add(file));
    return dataTransfer.files;
  };

  React.useEffect(() => {
    validateFirstName();
    validateLastName();
    validateEmail();
    validatecCEmail();
  }, [contactForm.suppliedFirstName, contactForm.suppliedLastName, contactForm.suppliedEmail, contactForm.ccEmail]);

  React.useEffect(() => {
    validateError();
  }, [showError]);

  const isFormValid = validFirstName && validLastName && validEmail && validCCEmail;
  const paddingValue = handleSuccess ? '12px 12px' : '24px 12px 12px 12px';

  return (
    <div>
      {errorResponse && (
        <div style={{ padding: paddingValue, maxWidth: '690px' }}>
          <EGDSBanner theme="error">
            <EGDSLayoutFlex alignItems="center" space="two">
              <EGDSLayoutFlexItem>
                <div>
                  <EGDSIcon name="error" spotlight={{ theme: 'standard', background: false }} />
                </div>
              </EGDSLayoutFlexItem>
              <EGDSLayoutFlexItem>
                <div>
                  <EGDSHeading size={7} tag="h2">
                    {errorResponse.message}
                  </EGDSHeading>
                  <EGDSText>{errorResponse.errorMessage}</EGDSText>
                </div>
              </EGDSLayoutFlexItem>
            </EGDSLayoutFlex>
          </EGDSBanner>
        </div>
      )}
      <EGDSCardContentSection padded>
        <EGDSLayoutGrid space="four" style={{ maxWidth: '668px' }}>
          <EGDSLayoutGridItem>
            <EGDSLayoutGrid columns={2} space="four">
              <EGDSLayoutFlex direction="column">
                <EGDSTextInput
                  disabled={loading ?? false}
                  label={data.contactForm.firstName.label}
                  placeholder={data.contactForm.firstName.placeholder}
                  name={data.contactForm.firstName.label}
                  value={contactForm.suppliedFirstName}
                  onChange={handleFirstNameChange}
                  invalid={validFirstName ? null : { primary: data.contactForm.firstName.validations[0].errorMessage }}
                />
              </EGDSLayoutFlex>
              <EGDSLayoutFlex direction="column">
                <EGDSTextInput
                  disabled={loading ?? false}
                  label={data.contactForm.lastName.label}
                  placeholder={data.contactForm.lastName.placeholder}
                  value={contactForm.suppliedLastName}
                  onChange={handleLastNameChange}
                  invalid={validLastName ? null : { primary: data.contactForm.lastName.validations[0].errorMessage }}
                />
              </EGDSLayoutFlex>
            </EGDSLayoutGrid>
          </EGDSLayoutGridItem>
          <EGDSLayoutFlex direction="column">
            <EGDSTextInput
              disabled={loading ?? false}
              label={data.contactForm.email.label}
              placeholder={data.contactForm.email.placeholder}
              value={contactForm.suppliedEmail}
              onChange={handleEmailChange}
              invalid={validEmail ? null : { primary: data.contactForm.email.validations[0].errorMessage }}
            />
          </EGDSLayoutFlex>
          <EGDSLayoutFlex direction="column">
            <EGDSTextInput
              disabled={loading ?? false}
              label={data.contactForm.suppliedEmail.label}
              placeholder={contactForm.ccEmail}
              value={contactForm.ccEmail}
              onChange={handleCCEmailChange}
              invalid={validCCEmail ? null : { primary: data.contactForm.suppliedEmail.validations[0].errorMessage }}
            />
          </EGDSLayoutFlex>
          <EGDSLayoutFlex direction="column">
            <EGDSTextArea
              disabled={loading ?? false}
              label={data.contactForm.message.label}
              placeholder={data.contactForm.message.placeholder}
              value={contactForm.description}
              onChange={handleMessageChange}
              invalid={validMessage ? null : { primary: data.contactForm.message.validations[0].errorMessage }}
            />
          </EGDSLayoutFlex>

          <EGDSLayoutFlex direction="column" key="file-upload">
            <EGDSLink className="text-link">
              <label htmlFor="file-upload">
                <EGDSIcon name={data.contactForm.attachmentLink.icon.token} />
                {data.contactForm.attachmentLink.text}
              </label>
            </EGDSLink>
            <input
              id="file-upload"
              type="file"
              multiple
              accept=".docx,.pdf,.ppt,.pptx,.xls,.xlsx,.csv,.gif,.jpg,.png"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            {isUploadingFiles ? (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <EGDSLoader />
              </div>
            ) : (
              <>
                <AttachmentList
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  setFileSizeError={setFileSizeError}
                />
                {fileSizeError && (
                  <EGDSText theme="negative">
                    {' '}
                    <EGDSIcon name={data.contactForm.attachmentError?.icon?.token} />{' '}
                    {data.contactForm.attachmentError?.message}{' '}
                  </EGDSText>
                )}
              </>
            )}
          </EGDSLayoutFlex>
          <EGDSLayoutFlex justifyContent="start" key="buttons">
            <EGDSLayoutGrid columns={['max-content', '82px']} space="four">
              <EGDSPrimaryButton
                onClick={createSalesforceCase}
                disabled={!isFormValid || loading || fileSizeError || isUploadingFiles}
              >
                {data.contactForm.sendButton.primary}
              </EGDSPrimaryButton>
              <EGDSSecondaryButton onClick={handleCancel} disabled={loading || isUploadingFiles}>
                {data.contactForm.cancelButton.primary}
              </EGDSSecondaryButton>
            </EGDSLayoutGrid>
          </EGDSLayoutFlex>
        </EGDSLayoutGrid>
      </EGDSCardContentSection>
    </div>
  );
};

ContactForm.displayName = 'Contact form';
export { ContactForm };
