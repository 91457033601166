import * as React from 'react';

import { EGDSBadgeStandard } from '@egds/react-core';
import { EGDSCard } from '@egds/react-core';
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from '@egds/react-core';
import { EGDSSpacing } from '@egds/react-core';
import { EGDSHeading, EGDSText } from '@egds/react-core';
import { useLocalization } from '@shared-ui/localization-context';

const HowCampaignGoalsWork = () => {
  const { formatText } = useLocalization();

  const paragraph1 = formatText('common.sideInfoSheets.howCampaignGoalsWorkParagraph1');
  const paragraph2 = formatText('common.sideInfoSheets.howCampaignGoalsWorkParagraph2');

  return (
    <EGDSLayoutFlex direction="column" space="four">
      <EGDSText>
        <span dangerouslySetInnerHTML={{ __html: paragraph1 }} />
      </EGDSText>
      <EGDSText>
        <span dangerouslySetInnerHTML={{ __html: paragraph2 }} />
      </EGDSText>
      <EGDSCard border={true} padded>
        <EGDSLayoutFlex direction="column">
          <EGDSLayoutFlexItem>
            <EGDSSpacing margin={{ blockstart: 'two' }} padding={{ inline: 'four' }}>
              <EGDSHeading>{formatText('common.sideInfoSheets.howCampaignGoalsWorkMaximizeClicksHeading')}</EGDSHeading>
            </EGDSSpacing>
          </EGDSLayoutFlexItem>
          <EGDSLayoutFlexItem>
            <EGDSSpacing margin={{ blockstart: 'two' }} padding={{ inline: 'four' }}>
              <EGDSText>{formatText('common.sideInfoSheets.howCampaignGoalsWorkMaximizeClicksDescription')}</EGDSText>
            </EGDSSpacing>
          </EGDSLayoutFlexItem>
        </EGDSLayoutFlex>
      </EGDSCard>
      <EGDSCard border={true} padded>
        <EGDSLayoutFlex direction="column" justifyContent="space-between">
          <EGDSLayoutFlex justifyContent="space-between">
            <EGDSSpacing margin={{ blockstart: 'two' }} padding={{ inline: 'four' }}>
              <EGDSHeading>{formatText('common.sideInfoSheets.howCampaignGoalsWorkMaximizeRoasHeading')}</EGDSHeading>
            </EGDSSpacing>
            <EGDSBadgeStandard theme="info">
              {formatText('common.sideInfoSheets.howCampaignGoalsWorkComingSoonBadgeText')}
            </EGDSBadgeStandard>
          </EGDSLayoutFlex>
          <EGDSLayoutFlexItem>
            <EGDSSpacing margin={{ blockstart: 'two' }} padding={{ inline: 'four' }}>
              <EGDSText theme="secondary">
                {formatText('common.sideInfoSheets.howCampaignGoalsWorkMaximizeRoasDescription')}
              </EGDSText>
            </EGDSSpacing>
          </EGDSLayoutFlexItem>
        </EGDSLayoutFlex>
      </EGDSCard>
    </EGDSLayoutFlex>
  );
};

export default HowCampaignGoalsWork;
