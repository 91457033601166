import React from 'react';

import { EGDSPagingNextButton, EGDSPagingPrevButton } from '@egds/react-core';
import { EGDSCard } from '@egds/react-core';
import { EGDSLayoutFlex } from '@egds/react-core';
import { EGDSText } from '@egds/react-core';
import { useLocalization } from '@shared-ui/localization-context';

interface ITablePaginationProps {
  page: number;
  totalPages: number;
  handlePrevious: () => void;
  handleNext: () => void;
  curDisplayedData: number;
  numDisplayed: number;
  tableRowsLength: number;
  pageSize: number;
}

export const TablePagination: React.FC<ITablePaginationProps> = ({
  page,
  totalPages,
  handlePrevious,
  handleNext,
  curDisplayedData,
  numDisplayed,
  tableRowsLength,
  pageSize,
}) => {
  const { formatText } = useLocalization();

  return (
    <div>
      <EGDSCard padded overflow={true} className={'overview-container'}>
        <EGDSLayoutFlex alignItems="center" space="twelve" justifyContent="end">
          <EGDSPagingPrevButton role="previousbutton" onClick={handlePrevious} disabled={page === 1} />
          <EGDSText size={300}>
            {formatText('campaignOverview.tablePaginationResultsText', {
              //todo rename in future
              start: curDisplayedData,
              end: numDisplayed,
              total: tableRowsLength,
            })}
          </EGDSText>
          <EGDSPagingNextButton
            role="nextbutton"
            onClick={handleNext}
            disabled={page === totalPages || tableRowsLength < pageSize}
          />
        </EGDSLayoutFlex>
      </EGDSCard>
    </div>
  );
};
