import * as React from 'react';

import { gql } from '@apollo/client';
import { HowToArticle } from '@components/helpAndSupport/fragments/how-to-article';
import { EGDSActionList, EGDSActionListItem, EGDSExpandoPeek } from '@egds/react-core';
import { EGDSCard, EGDSCardContentSection } from '@egds/react-core';
import { EGDSHeading, EGDSText } from '@egds/react-core';
import { FragmentComponent } from '@graphql/component-types';

import { SubCategory, SubSubCategory } from '../../../common/__generated__/api/types';

const fragment = gql`
  fragment subCategoryFragment on SubCategory {
    name
    expand {
      expandedLabel
      collapsedLabel
      expanded
    }
    subSubcategory {
      ...howToArticleFragment
    }
  }
  ${HowToArticle.fragment}
`;

interface ISubCategoryProps {
  data: SubCategory;
  onSubSubCategorySelect: (subSubCategory: SubSubCategory, subCategory: SubCategory) => void;
}
export const SubCategoryCard: FragmentComponent<ISubCategoryProps, SubCategory> = ({
  data,
  onSubSubCategorySelect,
}) => {
  const [visible, setVisible] = React.useState(false);
  const handleSubSubCatClick = (subSubCat: SubSubCategory) => {
    onSubSubCategorySelect(subSubCat, data);
  };
  const handleClick = () => {
    setVisible(!visible);
  };

  return (
    <>
      <EGDSCard padded style={{ width: '49.5%', padding: '24px 24px' }}>
        <EGDSCardContentSection padded>
          <EGDSHeading size={5}>{data.name}</EGDSHeading>
          <EGDSActionList>
            {data?.subSubcategory?.slice(0, 2).map((subSubCat: SubSubCategory) => (
              <EGDSActionListItem key={subSubCat.question}>
                <EGDSText size={400} weight="regular" theme="standard" align="left">
                  <button
                    style={{ backgroundColor: 'transparent', textAlign: 'left' }}
                    onClick={() => handleSubSubCatClick(subSubCat)}
                  >
                    {subSubCat.question}
                  </button>
                </EGDSText>
              </EGDSActionListItem>
            ))}
            {data?.subSubcategory?.length > 2 && (
              <EGDSExpandoPeek
                expandLabel={data.expand.expandedLabel}
                collapseLabel={data.expand.collapsedLabel}
                isVisible={visible}
                lines={2}
                handleClick={handleClick}
                className="help-support-expando-peek"
              >
                <EGDSActionList>
                  {data.subSubcategory.slice(2).map((subSubCat: SubSubCategory) => (
                    <EGDSActionListItem key={subSubCat.question} className="help-support-expando-peek-list-item">
                      <EGDSText size={400} weight="regular" theme="standard" align="left">
                        <button
                          style={{ backgroundColor: 'transparent', textAlign: 'left' }}
                          onClick={() => handleSubSubCatClick(subSubCat)}
                        >
                          {subSubCat.question}
                        </button>
                      </EGDSText>
                    </EGDSActionListItem>
                  ))}
                </EGDSActionList>
              </EGDSExpandoPeek>
            )}
          </EGDSActionList>
        </EGDSCardContentSection>
      </EGDSCard>
    </>
  );
};
SubCategoryCard.fragment = fragment;
SubCategoryCard.displayName = 'Sub category Card';
