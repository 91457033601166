import * as React from 'react';

import { EGDSCard, EGDSCardContentSection } from '@egds/react-core';
import { EGDSLayoutFlexItem, EGDSLayoutFlex } from '@egds/react-core';
import { EGDSPill, EGDSPillContainer } from '@egds/react-core';
import { EGDSSpacing } from '@egds/react-core';
import { EGDSHeading } from '@egds/react-core';

import { HelpAndSupportNavigationCard } from '../../../common/__generated__/api/types';

interface ICategoryNavigationCardProps {
  data: HelpAndSupportNavigationCard;
  onCategorySelect: (categoryId: string) => void;
  selectedCategoryId: string;
}
const CategoryNavigationCard: React.FC<ICategoryNavigationCardProps> = ({
  data,
  onCategorySelect,
  selectedCategoryId,
}) => {
  return (
    <EGDSCard padded>
      <EGDSSpacing margin={{ blockend: 'four' }}>
        <EGDSLayoutFlex>
          <EGDSCardContentSection padded>
            <EGDSLayoutFlexItem>
              <EGDSSpacing padding={{ blockend: 'three' }}>
                <EGDSHeading size={5}>{data.subHeading}</EGDSHeading>
              </EGDSSpacing>
            </EGDSLayoutFlexItem>
            <EGDSPillContainer wrap>
              {data.cardCategories.map((category) => (
                <EGDSPill
                  selected={category.id === selectedCategoryId}
                  id={category.id}
                  onChange={() => onCategorySelect(category.id)}
                  key={category.id}
                >
                  {category.label}
                </EGDSPill>
              ))}
            </EGDSPillContainer>
          </EGDSCardContentSection>
        </EGDSLayoutFlex>
      </EGDSSpacing>
    </EGDSCard>
  );
};

CategoryNavigationCard.displayName = 'Category navigation card';
export { CategoryNavigationCard };
