import React from 'react';

import { EGDSIcon } from '@egds/react-core';
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from '@egds/react-core';
import { EGDSLink } from '@egds/react-core';
import { EGDSListItem } from '@egds/react-core';
import { EGDSSpacingHr } from '@egds/react-core';
import { EGDSText } from '@egds/react-core';
import { NavLink, useLocation } from 'react-router-dom-v5-compat';

interface INavMenuItem {
  titleKey?: string;
  href: string;
  relatedPages: RegExp;
  icon: string;
}

interface INavItemProps {
  item: INavMenuItem;
  index: number;
  closeNavIfMobile: () => void;
}

const NavItem: React.FC<INavItemProps> = (props: INavItemProps) => {
  const { item, closeNavIfMobile } = props;
  const location = useLocation();

  const hashMap = new Map();
  hashMap.set('Campaign', 'work');
  hashMap.set('AdGroup', 'view_grid_large');

  let isActive = false;
  if (location.pathname === item.href) {
    isActive = true;
  } else if (item.relatedPages.test(location.pathname)) {
    isActive = true;
  }
  const linkClassName = isActive ? 'nav-link-active nav-link' : 'nav-link';
  const listItemClassName = isActive ? 'nav-item nav-item-active' : 'nav-item';

  return (
    <>
      <EGDSListItem className={listItemClassName} key={item.titleKey}>
        <EGDSLink className={linkClassName}>
          <NavLink to={item.href} onClick={closeNavIfMobile}>
            <EGDSLayoutFlex space="two">
              {item.icon != '' && (
                <EGDSLayoutFlexItem>
                  <EGDSIcon name={item.icon} />
                </EGDSLayoutFlexItem>
              )}
              <EGDSLayoutFlexItem>
                <EGDSText size={300} spacing={item.icon != '' ? 'one' : undefined}>
                  <span className="item-text">
                    {/* <LocalizedText message={item.titleKey} /> */}
                    {item.titleKey}
                  </span>
                </EGDSText>
              </EGDSLayoutFlexItem>
            </EGDSLayoutFlex>
          </NavLink>
        </EGDSLink>
      </EGDSListItem>
      <EGDSListItem>{item.titleKey === 'Campaign' && <EGDSSpacingHr />}</EGDSListItem>
    </>
  );
};

export { NavItem, INavMenuItem };
