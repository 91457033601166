import React from 'react';

import { ApolloError } from '@apollo/client';
import { EGDSCard } from '@egds/react-core';
import { EGDSIcon, EGDSIconSize } from '@egds/react-core';
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from '@egds/react-core';
import { EGDSLoader } from '@egds/react-core';
import { EGDSLineSkeleton } from '@egds/react-core';
import { EGDSSpacing } from '@egds/react-core';
import { EGDSText } from '@egds/react-core';
import { useLocalization } from '@shared-ui/localization-context';
import { AdvertiserReportingTableNoResults, TextWeight } from 'src/common/__generated__/api/types';

import { ErrorCard } from '../common/error-card';

export const TableLoading: React.FC<{
  text: string;
  weight: TextWeight;
}> = (props) => {
  return (
    <EGDSCard>
      <EGDSSpacing margin={{ blockstart: 'three', inlinestart: 'six' }}>
        <EGDSLineSkeleton />
      </EGDSSpacing>
      <EGDSSpacing margin={{ blockstart: 'two', inlinestart: 'six' }}>
        <EGDSLineSkeleton type="secondary" />
      </EGDSSpacing>
      <EGDSLayoutFlex alignContent="center" justifyContent="center">
        <EGDSSpacing padding={{ block: 'sixteen' }} margin={{ block: 'eight' }}>
          <EGDSText align="center">
            <EGDSLoader />
            <EGDSSpacing padding={{ blockstart: 'four' }}>
              <EGDSText weight={props.weight.toLowerCase() as 'bold' | 'regular' | 'medium'} size={500}>
                {props.text}
              </EGDSText>
            </EGDSSpacing>
          </EGDSText>
        </EGDSSpacing>
      </EGDSLayoutFlex>
    </EGDSCard>
  );
};

export const TableError: React.FC<{
  dataTableError: ApolloError;
  reloadTable: () => void;
}> = (props) => {
  const { formatText } = useLocalization();
  const { dataTableError } = props;
  const errorMessage =
    'classification' in (dataTableError.graphQLErrors[0]?.extensions || {}) &&
    dataTableError.graphQLErrors[0]?.extensions.classification !== 'DataFetchingException'
      ? `[Error ${dataTableError.message.slice(-3)}]`
      : formatText('reporting.error');
  return (
    <EGDSLayoutFlex alignContent="center" justifyContent="center">
      <EGDSSpacing margin={{ blockstart: 'eight', blockend: 'sixteen' }}>
        <EGDSText>
          <ErrorCard error={dataTableError} message={errorMessage} theme="primary" reloadClick={props.reloadTable} />
        </EGDSText>
      </EGDSSpacing>
    </EGDSLayoutFlex>
  );
};

export const NoResults: React.FC<{
  noResults: AdvertiserReportingTableNoResults;
}> = (props) => {
  return (
    <EGDSSpacing
      padding={{ blockstart: 'sixteen', blockend: 'sixteen' }}
      margin={{ blockstart: 'eight', blockend: 'sixteen' }}
    >
      <EGDSLayoutFlex alignContent="center" justifyContent="center">
        <EGDSText align="center">
          <EGDSSpacing
            margin={{
              blockend: 'four',
            }}
          >
            <EGDSIcon name={props.noResults.noResultsIcon.token} size={EGDSIconSize.XLARGE} />
          </EGDSSpacing>
          <EGDSSpacing
            margin={{
              blockend: 'two',
            }}
          >
            <EGDSText
              size={500}
              weight={props.noResults.primaryText.weight.toLowerCase() as 'bold' | 'regular' | 'medium'}
            >
              {props.noResults.primaryText.text}
            </EGDSText>
          </EGDSSpacing>
          <EGDSSpacing margin="two">
            <EGDSLayoutFlexItem maxWidth={230}>
              <EGDSText size={300}>{props.noResults.secondaryText.text}</EGDSText>
            </EGDSLayoutFlexItem>
          </EGDSSpacing>
        </EGDSText>
      </EGDSLayoutFlex>
    </EGDSSpacing>
  );
};
