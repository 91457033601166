export const DEFAULT_ADGROUP_LOCATION_TARGETINGS = [
  '165',
  '11721',
  '54',
  '11402',
  '125',
  '40',
  '164',
  '30',
  '123',
  '92',
  '198',
  '56',
  '135',
  '160',
  '48',
  '72',
  '62',
  '159',
  '200',
  '22',
  '65',
  '39',
  '172',
  '136',
  '3',
  '101',
  '63',
  '156',
  '6145969',
  '34',
  '177',
  '110',
  '100',
  '199',
  '52',
  '158',
  '114',
  '116',
  '106',
  '28',
  '115',
  '19',
  '182',
  '170',
  '187',
  '154',
  '35',
  '5',
  '73',
  '99',
  '122',
  '179',
  '32',
  '27',
  '87',
  '107',
  '150',
  '11713',
  '11702',
  '11712',
  '141',
  '6049220',
  '176',
  '104',
  '109',
  '108',
  '94',
  '11400',
  '167',
  '96',
  '37',
  '11704',
  '91',
  '11717',
  '15',
  '11412',
  '153',
  '81',
  '145',
  '25',
  '194',
  '11410',
  '127',
  '161',
  '11411',
  '89',
  '178',
  '11408',
  '11404',
  '124',
  '80',
  '77',
  '29',
  '118',
  '137',
  '6190474',
  '132',
  '57',
  '180',
  '93',
  '192',
  '133',
  '10',
  '11725',
  '11399',
  '155',
  '163',
  '61',
  '41',
  '11409',
  '11407',
  '131',
  '11706',
  '11708',
  '112',
  '185',
  '70',
  '11723',
  '11718',
  '126',
  '169',
  '69',
  '168',
  '68',
  '6',
  '186',
  '88',
  '121',
  '184',
  '148',
  '6069955',
  '181',
  '24',
  '49',
  '113',
  '44',
  '6143269',
  '33',
  '9',
  '50',
  '13',
  '6143274',
  '6069953',
  '75',
  '7',
  '6143084',
  '130',
  '16',
  '173',
  '6051403',
  '47',
  '138',
  '162',
  '67',
  '59',
  '103',
  '46',
  '11715',
  '197',
  '1290',
  '6056204',
  '11413',
  '11714',
  '78',
  '190',
  '11720',
  '58',
  '147',
  '151',
  '55539',
  '4',
  '26',
  '166',
  '146',
  '129',
  '2',
  '120',
  '11722',
  '102',
  '86',
  '64',
  '11405',
  '43',
  '55',
  '111',
  '11414',
  '66',
  '188',
  '105',
  '11401',
  '11724',
  '97',
  '11',
  '11406',
  '174',
  '84',
  '79',
  '17',
  '142',
  '42',
  '76',
  '18',
  '134',
  '53',
  '71',
  '117',
  '12',
  '140',
  '189',
  '183',
  '1',
  '45',
  '85',
  '149',
  '82',
  '11398',
  '98',
  '14',
  '83',
  '139',
  '157',
  '175',
  '11403',
  '11726',
  '196',
  '90',
  '95',
  '201',
  '20',
  '11719',
  '11107',
  '31',
  '144',
  '624566597709615000',
  '23',
  '8',
  '171',
  '51',
  '36',
  '11711',
  '74',
  '193',
  '143',
  '60',
  '21',
  '191',
  '38',
];
