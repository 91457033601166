import React, { SetStateAction } from 'react';

import LanguagePreferenceMenu from '@common/language-preference-menu';
import AccountSwitchOverlay from '@components/viewAndManage/account-switch-overlay';
import { UserPopoverSheet } from '@components/viewAndManage/user-popover-sheet';
import { EGDSButton, EGDSButtonSize, EGDSTertiaryButton } from '@egds/react-core';
import { GlobalNavigationSiteHeader } from '@egds/react-core';
import { EGDSHeaderBrandLogo } from '@egds/react-core';
import { EGDSIcon } from '@egds/react-core';
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from '@egds/react-core';
import { EGDSSheetTransition } from '@egds/react-core';
import { EGDSSpacing } from '@egds/react-core';
import { EGDSText } from '@egds/react-core';
import { useLayoutHelper } from '@hooks/use-layout-helper';
import { useStores } from '@hooks/use-stores';
import { useDialog } from '@shared-ui/dialog-context';
import { useLocalization } from '@shared-ui/localization-context';
import Measure from 'react-measure';
import { useNavigate } from 'react-router-dom-v5-compat';
import { CDN_URL } from 'src/constants';

interface IProps {
  navOpen: boolean;
  setNavOpen: React.Dispatch<SetStateAction<boolean>>;
  wizardMode: boolean;
}

const TopNavBar: React.FC<IProps> = ({ navOpen, setNavOpen, wizardMode }: IProps) => {
  const { user } = useStores();
  const { formatText } = useLocalization();
  const { isMobile } = useLayoutHelper();
  const navigate = useNavigate();
  const triggerRef = React.useRef();
  const [visible, setVisible] = React.useState(false);
  const [isDialogOpen, dialogActions, DialogComponent] = useDialog('switch-account');

  const toggleNav = () => {
    if (navOpen) {
      setNavOpen(false);
    } else {
      setNavOpen(true);
    }
  };

  const openDialog = () => {
    dialogActions.openDialog();
  };

  const closeDialog = () => {
    dialogActions.closeDialog();
  };

  const toggleVisibility = () => {
    setVisible(() => !visible);
  };

  return (
    <>
      <GlobalNavigationSiteHeader>
        <EGDSSpacing padding={{ inline: 'six' }}>
          <EGDSLayoutFlex justifyContent="space-between" alignItems="center" className="top-nav-bar">
            <EGDSLayoutFlexItem grow={0} className="top-nav-section">
              <div>
                {!wizardMode && (
                  <EGDSButton
                    data-testid="hamburger"
                    name="hamburger"
                    className="top-nav-hamburger-btn"
                    onClick={toggleNav}
                  >
                    <svg
                      className="nav-icon"
                      width="18"
                      height="12"
                      viewBox="0 0 18 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 12h18v-2H0v2zm0-5h18V5H0v2zm0-7v2h18V0H0z" fill="#FFF" fillRule="evenodd" />
                    </svg>
                  </EGDSButton>
                )}
                <EGDSHeaderBrandLogo
                  imgSrc={`${CDN_URL}images/ExpediaGroup-Logo.svg`}
                  href="#"
                  altText="Expedia Group Logo"
                />
              </div>
            </EGDSLayoutFlexItem>
            <EGDSLayoutFlexItem>
              <Measure>
                {({ measureRef }) => (
                  <div ref={measureRef}>
                    <EGDSLayoutFlex space="two" justifyContent="end">
                      <EGDSLayoutFlexItem className="top-nav-section">
                        {!isMobile && <EGDSText></EGDSText>}
                      </EGDSLayoutFlexItem>
                      <EGDSLayoutFlexItem className="top-nav-section"></EGDSLayoutFlexItem>
                      {user.partnerType == 'FLIGHT_SPONSORED_LISTING' && !user.adminContext && !wizardMode && (
                        <EGDSLayoutFlexItem className="top-nav-section">
                          <EGDSLayoutFlex space="four">
                            <EGDSLayoutFlexItem>
                              <p>{user.partnerName}</p>
                            </EGDSLayoutFlexItem>
                            <EGDSLayoutFlexItem>
                              <EGDSIcon name="flight" />
                            </EGDSLayoutFlexItem>
                            <EGDSLayoutFlexItem>
                              <EGDSIcon name="account_circle" />
                            </EGDSLayoutFlexItem>
                          </EGDSLayoutFlex>
                        </EGDSLayoutFlexItem>
                      )}
                      {(user.partnerType == 'LODGING_SPONSORED_LISTING' || user.adminContext) && (
                        <React.Fragment>
                          <EGDSLayoutFlexItem>
                            <LanguagePreferenceMenu />
                          </EGDSLayoutFlexItem>
                          {!wizardMode && (
                            <>
                              <EGDSLayoutFlexItem>
                                <EGDSTertiaryButton
                                  inverse
                                  size={EGDSButtonSize.MEDIUM}
                                  onClick={() => {
                                    user.adminContext ? navigate('/admn/help') : navigate('/help');
                                  }}
                                >
                                  <EGDSIcon name="help" className="top-nav-section" />
                                  {formatText('layout.topNavBar.helpButtonText')}
                                </EGDSTertiaryButton>
                              </EGDSLayoutFlexItem>
                              <EGDSLayoutFlexItem>
                                <UserPopoverSheet
                                  user={user}
                                  visible={visible}
                                  toggleVisibility={toggleVisibility}
                                  openDialog={openDialog}
                                  triggerRef={triggerRef}
                                />
                              </EGDSLayoutFlexItem>
                            </>
                          )}
                          <EGDSSheetTransition isVisible={isDialogOpen}>
                            <DialogComponent>
                              <AccountSwitchOverlay
                                isVisible={isDialogOpen}
                                closeDialog={closeDialog}
                                triggerRef={triggerRef}
                              />
                            </DialogComponent>
                          </EGDSSheetTransition>
                        </React.Fragment>
                      )}
                    </EGDSLayoutFlex>
                  </div>
                )}
              </Measure>
            </EGDSLayoutFlexItem>
          </EGDSLayoutFlex>
        </EGDSSpacing>
      </GlobalNavigationSiteHeader>
    </>
  );
};

export default TopNavBar;
