import React from 'react';

import { EGDSButtonSize, EGDSSecondaryButton } from '@egds/react-core';
import { EGDSCard } from '@egds/react-core';
import { EGDSIcon } from '@egds/react-core';
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from '@egds/react-core';
import { EGDSLink } from '@egds/react-core';
import { EGDSText } from '@egds/react-core';
import { useStores } from '@hooks/use-stores';
import { useLocalization } from '@shared-ui/localization-context';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom-v5-compat';

const Banner: React.FC = () => {
  const { banner: bannerStore } = useStores();
  const { formatText } = useLocalization();
  const onDismiss = () => {
    bannerStore.clearMessages();
  };

  const location = useLocation();
  const pathname = location.pathname;

  // todo, handle multiple?
  const bannerToShow = bannerStore.banners.find((banner) => new RegExp(banner.regex).test(pathname));

  if (!bannerToShow) {
    return null;
  }

  return (
    <>
      <EGDSCard padded style={{ height: '90px', margin: '1.5rem' }}>
        <EGDSLayoutFlex space={'three'} blockSize={'full_size'} alignItems={'center'}>
          <EGDSLayoutFlexItem>
            {bannerToShow.type === 'error' && <EGDSIcon name={'error'} spotlight={'warning'} />}
            {bannerToShow.type === 'success' && <EGDSIcon name={'check'} spotlight={'positive'} />}
          </EGDSLayoutFlexItem>
          <EGDSLayoutFlexItem grow={3}>
            <EGDSLayoutFlex direction={'column'} alignItems={'start'}>
              <EGDSLayoutFlexItem>
                <EGDSText weight={'bold'}>{bannerToShow.headline}</EGDSText>
              </EGDSLayoutFlexItem>
              <EGDSLayoutFlexItem>
                <EGDSText>{bannerToShow.message}</EGDSText>
              </EGDSLayoutFlexItem>
              {!bannerToShow.buttonText && (
                <EGDSLayoutFlexItem style={{ marginTop: '8px' }}>
                  <EGDSLink>
                    <button onClick={onDismiss}>{formatText('common.dismissButtonText')}</button>
                  </EGDSLink>
                </EGDSLayoutFlexItem>
              )}
            </EGDSLayoutFlex>
          </EGDSLayoutFlexItem>
          {bannerToShow.buttonText && (
            <EGDSLayoutFlex>
              <EGDSLayoutFlexItem style={{ marginTop: '8px', marginRight: '1rem' }}>
                <EGDSSecondaryButton onClick={onDismiss} size={EGDSButtonSize.MEDIUM}>
                  {bannerToShow.buttonText}
                </EGDSSecondaryButton>
              </EGDSLayoutFlexItem>
            </EGDSLayoutFlex>
          )}
        </EGDSLayoutFlex>
      </EGDSCard>
    </>
  );
};

export default observer(Banner);
