import { SetStateAction } from 'react';

import {
  IAd,
  IAdGroup,
  ICampaign,
  IContent,
  IFSLCreative,
  ILSLCreative,
  IWorkingObject,
} from '@components/campaignManagement/campaignWizard/campaign-wizard';
import moment from 'moment';
import {
  AdGroupLocationCard,
  AdGroupRequestInput,
  AdRequestInput,
  CampaignBudgetCard,
  NameCard,
  CampaignRequestInput,
  CampaignScheduleCard,
  EgdsMaxLengthInputValidation,
  EgdsMinLengthInputValidation,
  EgdsRegexInputValidation,
  MinValueInputValidation,
  ProductContext,
  SideBarCampaign,
  TargetingInput,
  ScheduledDateRangeValidation,
  SideBarLodgingCreative,
  CampaignWizardCard,
  CampaignPaymentProfileSelectionCard,
} from 'src/common/__generated__/api/types';
import { v4 as uuidv4 } from 'uuid';

import { DEFAULT_ADGROUP_LOCATION_TARGETINGS } from './location-targeting-list';

export const createDefaultContent = (context: ProductContext, adGroup?: IAdGroup): IContent => {
  const creative: IFSLCreative | ILSLCreative = {};

  if ([ProductContext.FLIGHT_SPONSORED_LISTING, ProductContext.LODGING_SPONSORED_LISTING].includes(context)) {
    creative.headline = '';
    creative.description = '';
    creative.imageUrl = '';
  }

  const content = {
    id: `temp-${uuidv4()}`,
    ad_type: context,
    locale: adGroup ? getLocalesAvailable(adGroup)[0].code : 'en-US',
    creativeLSL: context === ProductContext.LODGING_SPONSORED_LISTING ? creative : null,
    creativeFSL: context === ProductContext.FLIGHT_SPONSORED_LISTING ? creative : null,
    isUpdated: true,
  };

  return content;
};

interface ILocale {
  code: string;
  label: string;
}

export const AD_COPY_LOCALES = [
  { code: 'en-US', label: 'English' },
  { code: 'fr-FR', label: 'French' },
  { code: 'de-DE', label: 'German' },
  { code: 'es-ES', label: 'Spanish' },
  { code: 'it-IT', label: 'Italian' },
  { code: 'ja-JP', label: 'Japanese' },
  { code: 'sv-SE', label: 'Swedish' },
  { code: 'ko-KR', label: 'Korean' },
  { code: 'pt-BR', label: 'Portuguese' },
  { code: 'zh-CN', label: 'Chinese' },
  { code: 'th-TH', label: 'Thai' },
];
export const AD_COPY_LOCALES_MAX = AD_COPY_LOCALES.length;
export const getLocalesAvailable: (adGroup: IAdGroup) => ILocale[] = (adGroup) => {
  const langsUsed = Array.from(new Set(adGroup.ads.map((ad) => ad.content.locale)));
  const localsAvailable = AD_COPY_LOCALES.filter((option) => !langsUsed.includes(option.code));
  return localsAvailable;
};

export const getLocalesUsed: (adGroup: IAdGroup, currentLangCode: string) => ILocale[] = (adGroup, currentLangCode) => {
  let langsUsed = Array.from(new Set(adGroup.ads.map((ad) => ad.content.locale)));
  if (langsUsed.includes(currentLangCode)) {
    langsUsed = langsUsed.filter((code) => code !== currentLangCode);
  }
  const localsUsed = AD_COPY_LOCALES.filter((option) => langsUsed.includes(option.code));
  return localsUsed;
};

export const mergeCampaign = (
  sideBarCampaign: SideBarCampaign,
  context: ProductContext,
  existingCampaign?: ICampaign,
) => {
  const campaign: ICampaign = {
    hydrated: true,
    id: sideBarCampaign?.id,
    name: sideBarCampaign?.name,
    goal: sideBarCampaign.goalId,
    isPaused: sideBarCampaign?.isPaused,
    startDate: sideBarCampaign.startDate,
    endDate: sideBarCampaign.endDate,
    productId: sideBarCampaign?.productId,
    productName: sideBarCampaign?.productName,
    budget: Number(sideBarCampaign.budgetAmount),
    budgetCurrency: sideBarCampaign?.budgetCurrency,
    defaultBudgetChanged: existingCampaign?.defaultBudgetChanged || false,
    paymentProfileId: sideBarCampaign.paymentProfileId || '',
    isCampaignUpdated: sideBarCampaign?.id.includes('temp') ? true : false,
    campaignInputError: existingCampaign?.campaignInputError || false, //todo need oto check
    adGroups: sideBarCampaign?.adGroups?.map((adGroup) => {
      return {
        hydrated: adGroup.id.includes('temp') ? true : false,
        id: adGroup.id,
        name: adGroup.name,
        isPaused: adGroup.isPaused,
        isUpdated: adGroup.id.includes('temp') ? true : false,
        locationsUpdated: false,
        locations: adGroup.locations,
        adCounter: adGroup.ads.length,
        adGroupInputError: existingCampaign?.adGroups.find((ag) => ag.id === adGroup.id)?.adGroupInputError || false,
        ads: adGroup.ads?.map((ad) => {
          return {
            hydrated: ad.id.includes('temp') ? true : false,
            id: ad.id,
            isPaused: ad.isPaused,
            name: ad.name,
            adInputError:
              existingCampaign?.adGroups.find((ag) => ag.id === adGroup.id)?.ads.find((a) => a.id === ad.id)
                .adInputError || false,
            canOverrideImage: ad.canOverrideImage,
            isUpdated: ad.id.includes('temp') ? true : false,
            content: {
              id: ad.content.contentId,
              ad_type: context,
              locale: ad.content.locale,
              isUpdated: ad.id.includes('temp') ? true : false,
              creativeFSL:
                ad.content.adType == ProductContext.FLIGHT_SPONSORED_LISTING
                  ? {
                      headline: ad.content.creative.headline,
                      description: ad.content.creative.description,
                      imageUrl: ad.content.creative.imageUrl,
                    }
                  : null,
              creativeLSL:
                ad.content.adType == ProductContext.LODGING_SPONSORED_LISTING
                  ? {
                      headline: ad.content.creative.headline,
                      description: ad.content.creative.description,
                      isHeadlineRejected: (ad.content.creative as SideBarLodgingCreative).isHeadlineRejected,
                      isDescriptionRejected: (ad.content.creative as SideBarLodgingCreative).isDescriptionRejected,
                      imageUrl: ad.content.creative.imageUrl,
                      imageId: (ad.content.creative as SideBarLodgingCreative).imageId,
                    }
                  : null,
              approvalStatus: ad.content.approvalStatus,
            },
          };
        }),
      };
    }),
  };
  return campaign;
};

export const validateAdGroupName = (formBuilder: NameCard, name: string) => {
  const minLengthNameValidation = formBuilder.nameTextInput.validations[0] as EgdsMinLengthInputValidation;
  const maxLengthNameValidation = formBuilder.nameTextInput.validations[1] as EgdsMaxLengthInputValidation;

  if (name.length < minLengthNameValidation.minLength) {
    return minLengthNameValidation.errorMessage;
  }

  if (name.length > maxLengthNameValidation.maxLength) {
    return maxLengthNameValidation.errorMessage;
  }

  return null;
};

export const validateAdGroupLocation = (formBuilder: AdGroupLocationCard, locations: string[]) => {
  const validation = formBuilder.validationMessage.message;

  if (!locations || locations.length === 0) {
    return validation;
  }

  return null;
};

export const validateAdName = (formBuilder: NameCard, name: string) => {
  const minLengthNameValidation = formBuilder.nameTextInput.validations[0] as EgdsMinLengthInputValidation;
  const maxLengthNameValidation = formBuilder.nameTextInput.validations[1] as EgdsMaxLengthInputValidation;

  if (name.length < minLengthNameValidation.minLength) {
    return minLengthNameValidation.errorMessage;
  } else if (name.length > maxLengthNameValidation.maxLength) {
    return maxLengthNameValidation.errorMessage;
  } else {
    return null;
  }
};

export const validateCampaignName = (formBuilder: NameCard, name: string) => {
  const minLengthNameValidation = formBuilder.nameTextInput.validations[0] as EgdsMinLengthInputValidation;
  const maxLengthNameValidation = formBuilder.nameTextInput.validations[1] as EgdsMaxLengthInputValidation;

  if (name.length < minLengthNameValidation?.minLength) {
    return minLengthNameValidation?.errorMessage;
  } else if (name.length > maxLengthNameValidation?.maxLength) {
    return maxLengthNameValidation?.errorMessage;
  } else {
    return null;
  }
};

export const validateCampaignBudget = (formBuilder: CampaignBudgetCard, budget: number) => {
  const budgetMinValueValidation = formBuilder.textInputBox.validations[1] as MinValueInputValidation;

  if (budget < budgetMinValueValidation?.minValue) {
    return budgetMinValueValidation?.errorMessage;
  } else {
    return null;
  }
};

export const validateCampaignPaymentProfile = (
  formBuilder: CampaignPaymentProfileSelectionCard,
  paymentProfileId: string,
) => {
  const selectedPaymentProfile = formBuilder.paymentProfileList.find(
    (profile) => profile.profileId === paymentProfileId,
  );
  const isValidPaymentProfile = selectedPaymentProfile.isPaymentProfileComplete;
  if (!isValidPaymentProfile) {
    return 'Selected payment profile is incomplete.';
  }

  const validProfileFound = formBuilder.paymentProfileList.find((profile) => profile.isPaymentProfileComplete) || false;

  if (!validProfileFound) {
    return 'No valid payment profile found.';
  } else {
    return null;
  }
};

export const validateCampaignStartDate = (formBuilder: CampaignScheduleCard, startDate: string, endDate?: string) => {
  const startDateValidation = formBuilder.startDate.validations[0] as EgdsRegexInputValidation;
  const afterEndDateValidation = formBuilder.startDate.validations[1] as ScheduledDateRangeValidation;

  if (!startDate?.match(startDateValidation?.pattern)) {
    return startDateValidation?.errorMessage;
  } else {
    if (endDate) {
      const start = moment(startDate);
      const end = moment(endDate);
      if (start.isAfter(end)) {
        return afterEndDateValidation.errorMessage;
      }
    }
    return null;
  }
};

export const validateCampaignEndDate = (formBuilder: CampaignScheduleCard, startDate: string, endDate: string) => {
  const endDateValidation = formBuilder.endDate.validations[0] as EgdsRegexInputValidation;
  const maxEndDateValidation = formBuilder.endDate.validations[1] as EgdsMaxLengthInputValidation;
  const beforeStartDateValidation = formBuilder.endDate.validations[2] as ScheduledDateRangeValidation;

  if (!endDate?.match(endDateValidation?.pattern)) {
    return endDateValidation?.errorMessage;
  } else {
    const start = moment(startDate);
    const end = moment(endDate);

    if (end.isBefore(start)) {
      return beforeStartDateValidation?.errorMessage;
    }

    const diffInMillis = end.diff(start);
    const diffYearInMillis = moment.duration(maxEndDateValidation?.maxLength, 'years').asMilliseconds();

    if (diffInMillis > diffYearInMillis) {
      return maxEndDateValidation?.errorMessage;
    } else {
      return null;
    }
  }
};

export const prepareContent = (ad: IAd, adType: ProductContext) => {
  let lslContent = null;
  if (adType === ProductContext.LODGING_SPONSORED_LISTING) {
    const creative = ad.content.creativeLSL;
    lslContent = {
      canOverrideImage: ad.canOverrideImage,
      description: creative.description,
      headline: creative.headline,
      imageUrl: creative.imageUrl,
      imageId: creative.imageId,
    };
  }

  let fslContent = null;
  if (adType === ProductContext.FLIGHT_SPONSORED_LISTING) {
    const creative = ad.content.creativeFSL;
    fslContent = {
      description: creative.description,
      headline: creative.headline,
      imageUrl: creative.imageUrl,
    };
  }

  return {
    contentId: ad.content.id.includes('temp') ? null : ad.content.id,
    languages: [ad.content.locale],
    fslContent: fslContent,
    lslContent: lslContent,
    isUpdated: ad.content.isUpdated,
  };
};

export const prepareCampaignForPublishing = (campaign: ICampaign) => {
  const adGroupRequests: AdGroupRequestInput[] = [];
  for (const adGroup of campaign.adGroups) {
    const adRequests: AdRequestInput[] = [];
    for (const ad of adGroup.ads) {
      const adRequest: AdRequestInput = {
        adId: ad.id.includes('temp') ? null : ad.id,
        isUpdated: ad.isUpdated,
        adName: ad.name,
        isPaused: ad.isPaused,
        adCopy: prepareContent(ad, ad.content.ad_type),
      };
      adRequests.push(adRequest);
    }
    const locations: TargetingInput[] = adGroup.locations.map((location) => ({
      targetingValue: location,
    }));

    const adGroupRequest: AdGroupRequestInput = {
      adGroupId: adGroup.id.includes('temp') ? null : adGroup.id,
      isUpdated: adGroup.isUpdated,
      adGroupName: adGroup.name,
      isPaused: adGroup.isPaused,
      adGroupTargeting: {
        locationList: locations,
        locationsUpdated: adGroup.locationsUpdated,
        productId: { targetingValue: campaign.productId },
      },
      adList: adRequests,
    };
    adGroupRequests.push(adGroupRequest);
  }

  const campaignRequestInput: CampaignRequestInput = {
    budgetAmount: Number(campaign.budget),
    paymentProfileId: campaign.paymentProfileId,
    marketGoal: convertGoalToEnum(campaign.goal),
    campaignName: campaign.name,
    campaignId: campaign.id.includes('temp') ? null : campaign.id,
    isPaused: campaign.isPaused,
    campaignTargeting: {
      startDate: { targetingValue: campaign.startDate },
    },
    adGroupList: adGroupRequests,
    isCampaignUpdated: campaign.isCampaignUpdated,
  };

  if (campaign.endDate && campaign.endDate.length !== 0) {
    campaignRequestInput.campaignTargeting.endDate = {
      targetingValue: campaign.endDate,
    };
  }

  return campaignRequestInput;
};

export enum MarketGoal {
  LEADER = 'LEADER',
  PLAYER = 'PLAYER',
  TRACKER = 'TRACKER',
  UNKNOWN = 'UNKNOWN',
}

export const convertGoalToEnum = (goal: string): MarketGoal => {
  switch (goal) {
    case 'LEADER':
      return MarketGoal.LEADER;
    case 'PLAYER':
      return MarketGoal.PLAYER;
    case 'TRACKER':
      return MarketGoal.TRACKER;
    default:
      return MarketGoal.UNKNOWN;
  }
};

export const findCard = (stepCards: CampaignWizardCard[], cardType: string) => {
  return stepCards.find((card) => card.cardContent.__typename === cardType);
};

export const findCards = (stepCards: CampaignWizardCard[], cardType: string) => {
  return stepCards.filter((card) => card.cardContent.__typename === cardType);
};

export const createNewAdGroup = (
  context: ProductContext,
  campaign: ICampaign,
  setWorkingObject: React.Dispatch<SetStateAction<IWorkingObject>>,
  updateCampaign: React.Dispatch<SetStateAction<ICampaign>>,
  formatText: (key: string, ...args: unknown[]) => string,
  locale: string,
) => {
  const adGroupNumber = campaign.adGroups.length + 1;
  const newAdGroupId = `temp-${uuidv4()}`;

  const newAdGroupName =
    formatText('assets.campaignStructure.adGroupTitle') +
    ' ' +
    Intl.NumberFormat(locale.replace('_', '-')).format(adGroupNumber);
  const newAdName =
    formatText('assets.campaignStructure.adTitle') + ' ' + Intl.NumberFormat(locale.replace('_', '-')).format(1);

  campaign.adGroups.push({
    hydrated: true,
    id: newAdGroupId,
    name: newAdGroupName,
    locations: DEFAULT_ADGROUP_LOCATION_TARGETINGS,
    isPaused: false,
    locationsUpdated: true,
    isUpdated: true,
    ads: [
      {
        hydrated: true,
        isPaused: false,
        id: `temp-${uuidv4()}`,
        name: newAdName,
        content: createDefaultContent(context),
        canOverrideImage: true,
        isUpdated: true,
      },
    ],
  });

  setWorkingObject((cur) => {
    return {
      ...cur,
      adGroupId: newAdGroupId,
    };
  });
  updateCampaign({ ...campaign });
};

export const createNewAd = (
  context: ProductContext,
  campaign: ICampaign,
  adGroupId: string,
  setWorkingObject: React.Dispatch<SetStateAction<IWorkingObject>>,
  updateCampaign: React.Dispatch<SetStateAction<ICampaign>>,
  formatText: (key: string, ...args: unknown[]) => string,
  locale: string,
) => {
  const adGroup = campaign.adGroups.find((ag) => ag.id === adGroupId);
  const adNumberInGroup = adGroup.ads.length + 1;
  const newAdId = `temp-${uuidv4()}`;

  const newAdName =
    formatText('assets.campaignStructure.adTitle') +
    ' ' +
    Intl.NumberFormat(locale.replace('_', '-')).format(adNumberInGroup);

  adGroup.ads.push({
    hydrated: true,
    id: newAdId,
    isPaused: false,
    isUpdated: true,
    name: newAdName,
    content: createDefaultContent(context, adGroup),
    canOverrideImage: true,
  });

  updateCampaign({
    ...campaign,
  });
  setWorkingObject((cur) => {
    return { ...cur, adGroupId: adGroup.id, adId: newAdId };
  });
};
