import React, { useState } from 'react';

import TermsAndConditions from '@common/terms-conditions-dialog';
import { EGDSBanner } from '@egds/react-core';
import { useLayoutHelper } from '@hooks/use-layout-helper';
import { useStores } from '@hooks/use-stores';
import TopNavBar from '@layout/layout-content/top-nav-bar';
import { ViewContainer } from '@layout/layout-content/view-container';

interface IProps {
  children: React.ReactNode;
  ignorePadding?: boolean;
}

export const HelpSupportLayout: React.FC<IProps> = ({ children, ignorePadding }: IProps) => {
  const { navOpen, setNavOpen } = useLayoutHelper();
  const { user } = useStores();
  const { partnerType } = user;
  const [showTermsConditionsDialog] = useState<boolean>(partnerType !== 'FLIGHT_SPONSORED_LISTING');
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState<boolean>(false);

  const handleAcceptTerms = () => {
    setHasAcceptedTerms(true);
  };

  return (
    <>
      <TopNavBar navOpen={navOpen} setNavOpen={setNavOpen} wizardMode={false} />
      <noscript>
        <div style={{ borderRadius: 0 }}>
          <EGDSBanner theme="error">You need to enable javascript to run this app</EGDSBanner>
        </div>
      </noscript>
      <div className="help-support-layout">
        <ViewContainer navOpen={navOpen} setNavOpen={setNavOpen} ignorePadding={ignorePadding}>
          {showTermsConditionsDialog && !hasAcceptedTerms && <TermsAndConditions onAccept={handleAcceptTerms} />}
          {children}
        </ViewContainer>
      </div>
    </>
  );
};
