import React from 'react';

import { EGDSBanner } from '@egds/react-core';
import { EGDSIcon } from '@egds/react-core';
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from '@egds/react-core';
import { EGDSLink } from '@egds/react-core';
import { EGDSHeading, EGDSSubheading } from '@egds/react-core';

interface ReviewErrorProps {
  errorText: string;
  errorSubText: string;
  handleClick?: () => void;
  isLink?: boolean;
}

const ErrorBanner: React.FC<ReviewErrorProps> = ({ errorText, errorSubText, handleClick, isLink }) => {
  return (
    <EGDSBanner theme="error">
      <EGDSLayoutFlex alignItems="center" space="two">
        <EGDSLayoutFlexItem>
          <div>
            <EGDSIcon name="error" spotlight={{ theme: 'standard', background: false }} />
          </div>
        </EGDSLayoutFlexItem>
        <EGDSLayoutFlexItem>
          <div>
            <EGDSHeading size={7} tag="h2">
              {errorText}
            </EGDSHeading>
            {isLink ? (
              <EGDSLink>
                <button onClick={handleClick}>{errorSubText}</button>
              </EGDSLink>
            ) : (
              <EGDSSubheading className={'subFontColor'}>{errorSubText}</EGDSSubheading>
            )}
          </div>
        </EGDSLayoutFlexItem>
      </EGDSLayoutFlex>
    </EGDSBanner>
  );
};

export default ErrorBanner;
