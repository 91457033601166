import React, { SetStateAction } from 'react';

import { AdCopyReviewController } from '@controllers/admin/ad-copy-review-controller';
import { AuthController } from '@controllers/auth-controller';
import { controllers } from '@controllers/index';
import { EGDSList } from '@egds/react-core';
import { EGDSText } from '@egds/react-core';
import { useLayoutHelper } from '@hooks/use-layout-helper';
import { useStores } from '@hooks/use-stores';
import { INavMenuItem, NavItem } from '@layout/layout-content/side-nav-bar-item';
import { useLocalization } from '@shared-ui/localization-context';
import { ConfigStore } from '@stores/config-store';
import { PARTNER_TYPE, UserStore } from '@stores/user-store';
import { hasControllerAccess } from '@utils/controller-utils';
import { observer } from 'mobx-react';

export const getNavList = (env: string, user: UserStore): Array<INavMenuItem> => {
  const { formatText } = useLocalization();
  const menu: Array<INavMenuItem> = [];
  const { partnerRoles, partnerType, adminContext } = user;

  if (partnerType === PARTNER_TYPE.FLIGHT_SPONSORED_LISTING && !adminContext) {
    menu.push({
      titleKey: formatText('controllers.reports'),
      href: '/reporting',
      relatedPages: new RegExp('null'),
      icon: '',
    });
    menu.push({
      titleKey: formatText('controllers.logout'),
      href: '/logout',
      relatedPages: new RegExp('null'),
      icon: '',
    });

    return menu;
  }

  controllers.forEach((Controller) => {
    const controller = new Controller() as AuthController;
    if ('navIcon' in controller === false || !controller.navIcon) {
      return;
    }
    let relatedPages = new RegExp('null');

    if (controller instanceof AdCopyReviewController) {
      relatedPages = new RegExp('copyReview');
    }

    const menuItem = {
      titleKey: formatText(controller.pageTitle),
      href: controller.path,
      relatedPages: relatedPages,
      icon: controller.navIcon,
    };

    if (!hasControllerAccess(partnerRoles, controller.accessRoles)) {
      return;
    }

    if (adminContext && controller.isAdminView) {
      menu.push(menuItem);
    }

    if (!adminContext && controller.isPartnerView) {
      menu.push(menuItem);
    }
  });

  return menu;
};

export interface ISideNavBarProps {
  navOpen: boolean;
  setNavOpen: React.Dispatch<SetStateAction<boolean>>;
  config?: ConfigStore;
  user?: UserStore;
}

const SideNavBar: React.FC<ISideNavBarProps> = observer((props: ISideNavBarProps) => {
  const { isMobile } = useLayoutHelper();
  const { formatText, formatDate } = useLocalization();
  const { setNavOpen, navOpen } = props;
  const { user, config } = useStores();

  function closeNavIfMobile() {
    if (isMobile) {
      setNavOpen(false);
    }
  }

  let sideNavBarClass = isMobile ? `side-nav-bar side-nav-bar-mobile` : `side-nav-bar`;
  if (navOpen) {
    sideNavBarClass += ' side-nav-open';
  }
  const navList = getNavList(config.appConfig.deployedEnv, user);

  const formattedDate = formatDate(new Date(), { skeleton: 'y' });

  return (
    <div data-testid="side-nav" className={sideNavBarClass}>
      <nav id="navigation-menu-items" className="navigation-menu-items">
        <EGDSList bullet="no-bullet" data-control="accordion">
          {navList.map((item: INavMenuItem, index: number) => (
            <NavItem key={index} item={item} index={index} closeNavIfMobile={closeNavIfMobile} />
          ))}
        </EGDSList>
        <footer id="side-nav-bar-footer" className="side-nav-bar-footer">
          <EGDSText size={200} spacing="six">
            &#169;
            {formatText('sideBar.copyRightText', formattedDate)}
            <br />
            <br />
            <a href="https://legal.expediagroup.com/privacy/privacy-and-cookies-statements/partner-sites/consolidated-ad-portal/">
              {formatText('sideBar.privaryStatementLinkText')}
            </a>{' '}
            &bull;{' '}
            <a href="https://legal.expediagroup.com/privacy/privacy-and-cookies-statements/partner-sites1/consolidated-ad-portal1/">
              {formatText('sideBar.cookiesUsageLinkText')}
            </a>
          </EGDSText>
        </footer>
      </nav>
    </div>
  );
});

export { SideNavBar };
