import React, { useRef, useState } from 'react';

import { gql } from '@apollo/client';
import {
  EGDSBadgeStandard,
  EGDSCard,
  EGDSCardBackgroundThemes,
  EGDSIcon,
  EGDSIconSize,
  EGDSLayoutGrid,
  EGDSLayoutGridItem,
  EGDSMoreInfoTrigger,
  EGDSSpacing,
  EGDSText,
} from '@egds/react-core';
import { EGDSTypeSize, EGDSTypeWeight } from '@egds/react-core/text';
import { FragmentComponent } from '@graphql/component-types';
import useClickOutside from '@hooks/use-click-outside';

import { BudgetForecastMetricsCard } from '../../common/__generated__/api/types';

export const BUDGET_FORECAST_METRICS_CARD_FRAGMENT = gql`
  fragment BudgetForecastMetricsCardFragment on BudgetForecastMetricsCard {
    title
    description
    tooltip
    additionalSpendTitle
    additionalSpendTooltip
    additionalClicksTitle
    additionalClicksTooltip
    additionalRoomNightsTitle
    additionalRoomNightsTooltip
    additionalRevenueTitle
    additionalRevenueTooltip
    metrics {
      additionalClicks {
        lowerBound {
          formattedValue
        }
        midpoint {
          formattedValue
        }
        upperBound {
          formattedValue
        }
        formattedBoundsRange
      }
      additionalRoomNights {
        lowerBound {
          formattedValue
        }
        midpoint {
          formattedValue
        }
        upperBound {
          formattedValue
        }
        formattedBoundsRange
      }
      additionalRevenue {
        lowerBound {
          formattedValue
        }
        midpoint {
          formattedValue
        }
        upperBound {
          formattedValue
        }
        formattedBoundsRange
      }
      additionalSpend {
        lowerBound {
          formattedValue
        }
        midpoint {
          formattedValue
        }
        upperBound {
          formattedValue
        }
        formattedBoundsRange
      }
    }
    badge {
      text
    }
    tooltipIcon {
      description
      id
      token
    }
    footnoteText
  }
`;

interface IBudgetForecastedMetricsProps {
  data: BudgetForecastMetricsCard;
}

export const BudgetForecastedMetrics: FragmentComponent<IBudgetForecastedMetricsProps, BudgetForecastMetricsCard> = ({
  data,
}) => {
  interface PopupProps {
    position: string;
    label: string;
    description: string;
    labelSize: EGDSTypeSize;
    labelWeight: EGDSTypeWeight;
  }

  const Popup: React.FC<PopupProps> = ({ position, label, description, labelSize, labelWeight }) => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const popupRef = useRef(null);

    const togglePopup = () => {
      setIsPopupVisible((prev) => !prev);
    };

    useClickOutside(popupRef, () => {
      if (isPopupVisible) {
        togglePopup();
      }
    });

    const popupStyle: React.CSSProperties = {
      position: 'absolute',
      bottom: '140%',
      left: position,
    };

    return (
      <div style={{ position: 'relative' }} ref={popupRef}>
        <EGDSMoreInfoTrigger align="trailing" onClick={togglePopup}>
          <span>
            <EGDSText size={labelSize} weight={labelWeight}>
              {label} <EGDSIcon name={data.tooltipIcon.token} size={EGDSIconSize.SMALL} theme={'secondary'} />
            </EGDSText>
          </span>
        </EGDSMoreInfoTrigger>
        {isPopupVisible && (
          <EGDSCard className={'opportunities-budget-insights-popover'} style={popupStyle}>
            <EGDSLayoutGrid columns={['12fr', '1fr']} space={'one'}>
              <EGDSLayoutGridItem>
                <EGDSSpacing padding={{ block: 'two', inlinestart: 'two' }}>
                  <EGDSText size={300} weight={'regular'}>
                    {description}
                  </EGDSText>
                </EGDSSpacing>
              </EGDSLayoutGridItem>
              <EGDSLayoutGridItem>
                <EGDSMoreInfoTrigger onClick={togglePopup}>
                  <a>
                    <EGDSIcon size={EGDSIconSize.SMALL} name={'close'} />
                  </a>
                </EGDSMoreInfoTrigger>
              </EGDSLayoutGridItem>
            </EGDSLayoutGrid>
          </EGDSCard>
        )}
      </div>
    );
  };

  return (
    <EGDSCard backgroundTheme={EGDSCardBackgroundThemes.HIGHLIGHT} padded style={{ overflow: 'visible' }}>
      <Popup position={'0px'} label={data.title} description={data.tooltip} labelSize={400} labelWeight={'medium'} />
      <EGDSSpacing padding={{ blockstart: 'two', blockend: 'four' }}>
        <EGDSText size={200} weight={'regular'}>
          {data.description}
        </EGDSText>
      </EGDSSpacing>

      <Popup
        position={'0px'}
        label={data.additionalSpendTitle}
        description={data.additionalSpendTooltip}
        labelSize={200}
        labelWeight={'medium'}
      />
      <EGDSSpacing padding={{ blockstart: 'two', blockend: 'four' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px' }}>
          <EGDSBadgeStandard size={'small'} theme={'standard'} text={data.badge.text} />
          <EGDSText inline size={500} weight={'medium'}>
            {data.metrics.additionalSpend.formattedBoundsRange}
          </EGDSText>
        </div>
      </EGDSSpacing>

      <Popup
        position={'0px'}
        label={data.additionalRevenueTitle}
        description={data.additionalRevenueTooltip}
        labelSize={200}
        labelWeight={'medium'}
      />
      <EGDSSpacing padding={{ blockstart: 'two', blockend: 'four' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px' }}>
          <EGDSBadgeStandard size={'small'} theme={'standard'} text={data.badge.text} />
          <EGDSText inline size={500} weight={'medium'}>
            {data.metrics.additionalRevenue.formattedBoundsRange}
          </EGDSText>
        </div>
      </EGDSSpacing>

      <Popup
        position={'0px'}
        label={data.additionalClicksTitle}
        description={data.additionalClicksTooltip}
        labelSize={200}
        labelWeight={'medium'}
      />
      <EGDSSpacing padding={{ blockstart: 'two', blockend: 'four' }}>
        <EGDSText size={500} weight={'bold'}>
          {data.metrics.additionalClicks.formattedBoundsRange}
        </EGDSText>
      </EGDSSpacing>

      <Popup
        position={'0px'}
        label={data.additionalRoomNightsTitle}
        description={data.additionalRoomNightsTooltip}
        labelSize={200}
        labelWeight={'medium'}
      />
      <EGDSSpacing padding={{ blockstart: 'two', blockend: 'four' }}>
        <EGDSText size={500} weight={'bold'}>
          {data.metrics.additionalRoomNights.formattedBoundsRange}
        </EGDSText>
      </EGDSSpacing>

      <EGDSText size={200} weight={'regular'}>
        {data.footnoteText}
      </EGDSText>
    </EGDSCard>
  );
};
BudgetForecastedMetrics.displayName = 'BudgetForecastedMetrics';
BudgetForecastedMetrics.fragment = BUDGET_FORECAST_METRICS_CARD_FRAGMENT;
