import * as React from 'react';

import { EGDSFigure, EGDSFigureAspectRatioType, EGDSImage } from '@egds/react-core';
import { EGDSLayoutFlex } from '@egds/react-core';
import { EGDSText, EGDSHeading } from '@egds/react-core';
import { useBexApi } from '@shared-ui/bex-api-context';
import { useLocalization } from '@shared-ui/localization-context';
import { useDyffContext } from 'dyff-plugin/dist/context';
import { CDN_URL } from 'src/constants';

const PartnerAccountInfo = () => {
  const { formatText } = useLocalization();
  const { isFeatureEnabled } = useDyffContext();
  const {
    context: { locale },
  } = useBexApi();

  const showGAImage = isFeatureEnabled('GA_ACCOUNT_STRUCTURE_IMAGE');

  const imageSrc = showGAImage
    ? `${CDN_URL}images/account-structure/v1/${locale}_partner_account_structure.png`
    : `${CDN_URL}images/partner_account_info_beta.png`;

  return (
    <EGDSLayoutFlex direction="column" space="four">
      <EGDSHeading>{formatText('common.sideInfoSheets.partnerAccountInfoHeading')}</EGDSHeading>
      <EGDSText>{formatText('common.sideInfoSheets.partnerAccountInfoText')}</EGDSText>
      <EGDSFigure
        imageFit="contain"
        ratio={EGDSFigureAspectRatioType.R1_1}
        style={{ marginTop: '.5rem', marginBottom: '.5rem' }}
      >
        <EGDSImage src={imageSrc} />
      </EGDSFigure>
      <EGDSHeading>{formatText('common.sideInfoSheets.partnerAccountPropertiesInfoHeading')}</EGDSHeading>
      <EGDSText>{formatText('common.sideInfoSheets.partnerAccountPropertiesInfoText')}</EGDSText>
      <EGDSHeading>{formatText('common.sideInfoSheets.partnerAccountPaymentProfileInfoHeading')}</EGDSHeading>
      <EGDSText>{formatText('common.sideInfoSheets.partnerAccountPaymentProfileInfoText')}</EGDSText>
      <EGDSHeading>{formatText('common.sideInfoSheets.partnerAccountUsersInfoHeading')}</EGDSHeading>
      <EGDSText>{formatText('common.sideInfoSheets.partnerAccountUsersInfoText')}</EGDSText>
    </EGDSLayoutFlex>
  );
};

export default PartnerAccountInfo;
