import * as React from 'react';

import { EGDSFigure, EGDSFigureAspectRatioType, EGDSImage } from '@egds/react-core';
import { EGDSLayoutFlex } from '@egds/react-core';
import { EGDSText, EGDSHeading } from '@egds/react-core';
import { useBexApi } from '@shared-ui/bex-api-context';
import { useLocalization } from '@shared-ui/localization-context';
import { useDyffContext } from 'dyff-plugin/dist/context';
import { CDN_URL } from 'src/constants';

const PaymentProfileInfo = () => {
  const { formatText } = useLocalization();
  const { isFeatureEnabled } = useDyffContext();
  const {
    context: { locale },
  } = useBexApi();

  const showGAImage = isFeatureEnabled('GA_PAYMENT_STRUCTURE_IMAGE');

  return (
    <EGDSLayoutFlex direction="column" space="four">
      <EGDSHeading>{formatText('common.sideInfoSheets.paymentProfileInfoHeading')}</EGDSHeading>
      <EGDSText>{formatText('common.sideInfoSheets.paymentProfileInfoText')}</EGDSText>
      {showGAImage && (
        <EGDSFigure imageFit="contain" ratio={EGDSFigureAspectRatioType.R3_2}>
          <EGDSImage src={`${CDN_URL}images/payment-profile-structure/v1/${locale}_payment_profile_structure.png`} />
        </EGDSFigure>
      )}
      <EGDSHeading>{formatText('common.sideInfoSheets.paymentProfileCurrencyInfoHeading')}</EGDSHeading>
      <EGDSText>{formatText('common.sideInfoSheets.paymentProfileCurrencyInfoText')}</EGDSText>
    </EGDSLayoutFlex>
  );
};

export default PaymentProfileInfo;
