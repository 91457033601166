import React from 'react';

import { EGDSSheet, EGDSSheetContent } from '@egds/react-core';
import { EGDSToolbar } from '@egds/react-core';
import { useLocalization } from '@shared-ui/localization-context';
import SelectAccount from '@views/select-account';

interface AccountSwitchOverlayProps {
  isVisible: boolean;
  closeDialog: () => void;
  triggerRef: React.RefObject<HTMLElement>;
}

const AccountSwitchOverlay: React.FC<AccountSwitchOverlayProps> = ({ isVisible, closeDialog, triggerRef }) => {
  const { formatText } = useLocalization();
  return (
    <EGDSSheet type="centered" isVisible={isVisible} triggerRef={triggerRef}>
      <EGDSToolbar
        navigationContent={{
          onClick: closeDialog,
          navIconLabel: formatText('accountSwitchOverlay.closeIconText'),
        }}
        toolbarTitle={formatText('accountSwitchOverlay.switchAccountText')}
      />
      <EGDSSheetContent>
        <SelectAccount history={undefined} location={undefined} match={undefined} />
      </EGDSSheetContent>
    </EGDSSheet>
  );
};

export default AccountSwitchOverlay;
