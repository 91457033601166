import React, { useState } from 'react';

import {
  EGDSLayoutFlex,
  EGDSLayoutFlexItem,
  EGDSSpacing,
  EGDSTextInput,
  EGDSPrimaryButton,
  EGDSMenu,
  EGDSMenuTrigger,
  EGDSFakeInput,
  EGDSIcon,
  EGDSMenuContainer,
  EGDSMenuList,
  PositionType,
  EGDSIconSize,
} from '@egds/react-core';
import { useLocalization } from '@shared-ui/localization-context';

interface CampaignSearchBarProps {
  filterOptions: { label: string; value: string }[];
  selectedFilter: string;
  inputValue: string;
  onFilterChange: (value: string) => void;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  submitButtonLabel: string;
  partnerAccountIdSearchParamEnabled?: boolean;
}

const CampaignSearchBar: React.FC<CampaignSearchBarProps> = ({
  filterOptions,
  selectedFilter,
  inputValue,
  onFilterChange,
  onInputChange,
  onSubmit,
  submitButtonLabel,
  partnerAccountIdSearchParamEnabled = false,
}) => {
  const { formatText } = useLocalization();
  const [isFilterMenuOpen, setFilterMenuOpen] = useState(false);
  const selectedFilterLabel = filterOptions.find((option) => option.value === selectedFilter)?.label;

  const handleFilterSelect = (value: string) => {
    onFilterChange(value);
    setFilterMenuOpen(false);
  };

  const filteredOptions = partnerAccountIdSearchParamEnabled
    ? filterOptions
    : filterOptions.filter((option) => option.value !== 'PARTNER_ACCOUNT_ID');

  // Track if the user has typed at least once.
  const [hasUserTyped, setHasUserTyped] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!hasUserTyped && event.target.value) {
      setHasUserTyped(true);
    }
    onInputChange(event);
  };

  const handleSubmit = () => {
    if (!inputValue) {
      setHasUserTyped(false);
    }
    onSubmit();
  };

  return (
    <EGDSLayoutFlex alignItems="center" space="three" wrap="wrap">
      <EGDSLayoutFlexItem grow={1}>
        <EGDSSpacing>
          <div>
            <EGDSMenu
              id="filter-menu"
              isOpen={isFilterMenuOpen}
              onTriggerClick={() => setFilterMenuOpen((prev) => !prev)}
            >
              <EGDSMenuTrigger>
                <div className="uitk-field uitk-field-select-field has-floatedLabel-label has-placeholder">
                  <EGDSFakeInput label={formatText('campaignSearchBar.searchBy')} value={selectedFilterLabel} />
                  <EGDSIcon name="expand_more" className={'uitk-field-select-icon'} size={EGDSIconSize.SMALL} />
                </div>
              </EGDSMenuTrigger>
              <EGDSMenuContainer position={PositionType.LEFT} className="admin-search">
                <EGDSMenuList
                  items={filteredOptions.map((option) => ({
                    className: 'uitk-list-item',
                    label: option.label,
                    selected: selectedFilter === option.value,
                    onClick: () => handleFilterSelect(option.value),
                  }))}
                />
              </EGDSMenuContainer>
            </EGDSMenu>
          </div>
        </EGDSSpacing>
      </EGDSLayoutFlexItem>
      <EGDSLayoutFlexItem grow={5}>
        <div>
          <EGDSTextInput
            label={formatText('campaignSearchBar.inputLabel')}
            // placeholder={inputLabel}
            hasClearButton
            onChange={handleInputChange}
            value={inputValue}
            data-testid="admin-search-input"
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleSubmit();
              }
            }}
          />
        </div>
      </EGDSLayoutFlexItem>
      <EGDSLayoutFlexItem grow={0}>
        <EGDSPrimaryButton onClick={handleSubmit} disabled={!hasUserTyped && !inputValue}>
          {submitButtonLabel}
        </EGDSPrimaryButton>
      </EGDSLayoutFlexItem>
    </EGDSLayoutFlex>
  );
};

export default CampaignSearchBar;
