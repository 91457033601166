import * as React from 'react';

import { EGDSBreadcrumbs } from '@egds/react-core';
import { EGDSTertiaryButton } from '@egds/react-core';
import { EGDSCard } from '@egds/react-core';
import { EGDSIcon } from '@egds/react-core';
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from '@egds/react-core';
import { EGDSSpacing } from '@egds/react-core';
import { EGDSText } from '@egds/react-core';
import { SubSubCategory } from 'src/common/__generated__/api/types';

import { ContactForm } from './contact-form';

interface IIntakeFormProps {
  data: SubSubCategory;
  parentCategory: string;
  subCategory: string;
  question: string;
  onBack?: () => void;
  renderToast: (successMsg: string) => void;
}

const IntakeForm: React.FC<IIntakeFormProps> = ({
  data,
  onBack,
  parentCategory,
  subCategory,
  question,
  renderToast,
}) => {
  const crumbs = [
    <EGDSText key={parentCategory} inline={true}>
      {parentCategory}
    </EGDSText>,
    <EGDSText key={subCategory} inline={true}>
      {subCategory}
    </EGDSText>,
    <EGDSText key={question} inline={true}>
      {question}
    </EGDSText>,
  ];

  return (
    <EGDSCard padded>
      <EGDSSpacing>
        <EGDSTertiaryButton onClick={onBack}>
          <EGDSIcon name={data.backIcon.token} />
          {data.backButton.primary}
        </EGDSTertiaryButton>
      </EGDSSpacing>
      <div style={{ padding: '0px 0px 4px 12px' }}>
        <EGDSBreadcrumbs crumbs={crumbs} hasDynamicInlineCrumbs={true} />
      </div>
      <div style={{ padding: '0px 12px' }}>
        <EGDSLayoutFlex direction={'column'} alignItems={'start'} space={'two'}>
          <EGDSLayoutFlexItem>
            <EGDSText size={400} weight={'bold'}>
              {data.contactForm.secondaryTitle}
            </EGDSText>
          </EGDSLayoutFlexItem>
          <EGDSLayoutFlexItem>
            <EGDSText>{data.contactForm.secondarySubtitle}</EGDSText>
          </EGDSLayoutFlexItem>
        </EGDSLayoutFlex>
      </div>
      <ContactForm
        data={data}
        parentCategory={parentCategory}
        subCategory={subCategory}
        question={question}
        onBack={onBack}
        handleCancel={onBack}
        renderToast={renderToast}
      />
    </EGDSCard>
  );
};

IntakeForm.displayName = 'Intake form';
export { IntakeForm };
