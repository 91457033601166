/* eslint-disable @typescript-eslint/no-unused-vars */
import { CategorizedResults, CategoryMap, ESSResponse, TypeaheadAdapter, TypeaheadResult } from '@egds/react-core';

import { CampaignOption, AdGroupOption } from '../../common/__generated__/api/types';

export abstract class BaseTypeAheadAdapter implements TypeaheadAdapter {
  public abstract fetchResults(term: string): Promise<TypeaheadResult[]>;
  public abstract adaptResponseToTypeaheadResults(data: ESSResponse, term?: string): TypeaheadResult[];

  public fetchResponse(term: string): Promise<ESSResponse> {
    throw new Error('Method not implemented.');
  }

  public fetchResultsCategorized(term: string): Promise<CategorizedResults[]> {
    return new Promise(() => {});
  }

  public fetchRecentSearchResults(): Promise<TypeaheadResult[] | CategorizedResults[]> {
    return new Promise(() => {});
  }

  public fetchRecentSearchResultsCategorized(): Promise<CategorizedResults[]> {
    return new Promise(() => {});
  }

  public fetchAlternativeResults(term: string): Promise<TypeaheadResult[] | CategorizedResults[]> {
    return new Promise(() => {});
  }

  public logItemSelection?(suggestionItem: TypeaheadResult) {
    // console.log(suggestionItem);
  }

  public onItemSelection?(data: object): Promise<object> {
    return new Promise((res) => {
      res(data);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public logResponse(results: any): Promise<any> {
    return new Promise(() => {});
  }

  public typeaheadDialogOpened(prefill?: string) {
    // console.log(prefill);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public logSuggestionShown(term: string, results: any[]) {
    // console.log(term);
    // console.log(results);
  }

  public logTypeaheadDismiss(): void {}

  public setTypeaheadCategories(): void {}

  public getTypeaheadCategories(): CategoryMap {
    return {};
  }

  public isHistoryDetailEnabled(): boolean {
    return true;
  }
}

export class CampaignManagementTypeAheadAdapter extends BaseTypeAheadAdapter {
  private items: CampaignOption[];
  private icon: string;

  constructor(items: CampaignOption[], icon: string) {
    super();
    this.items = items;
    this.icon = icon;
  }

  adaptResponseToTypeaheadResults(json: ESSResponse, term?: string, historyDetail?: boolean): TypeaheadResult[] {
    return json.sr.map((x) => {
      return {
        icon: this.icon,
        name: x.campaignName,
        value: x.campaignName,
        title: x.campaignName,
        data: x,
      };
    });
  }

  fetchResults(term: string): Promise<TypeaheadResult[]> {
    return new Promise((res) => {
      const result = this.items.filter((x) => x.campaignName.toLocaleLowerCase().includes(term.toLocaleLowerCase()));
      const p = this.adaptResponseToTypeaheadResults({ sr: result });
      return res(p);
    });
  }
}

export class AdGroupManagementTypeAheadAdapter extends BaseTypeAheadAdapter {
  private items?: AdGroupOption[];
  private icon: string;

  constructor(items?: AdGroupOption[], icon?: string) {
    super();
    this.items = items;
    this.icon = icon;
  }

  adaptResponseToTypeaheadResults(json: ESSResponse): TypeaheadResult[] {
    return json.sr.map((x) => {
      return {
        icon: this.icon,
        name: x.adGroupName,
        value: x.adGroupName,
        title: x.adGroupName,
        data: x,
      };
    });
  }

  fetchResults(term: string): Promise<TypeaheadResult[]> {
    return new Promise((res) => {
      const result = this.items.filter((x) => x.adGroupName.toLocaleLowerCase().includes(term.toLocaleLowerCase()));
      const p = this.adaptResponseToTypeaheadResults({ sr: result });
      return res(p);
    });
  }
}
